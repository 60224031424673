import { all } from "redux-saga/effects";
import { wathcAddDid } from "./addDid";
import { appSagas } from "./app";
import { watchLogin } from "./auth";
import { watchavailabilityDid } from "./availabilityDid";
import { watchavailabilityUser } from "./availabilityUserName";
import { watchBulkDeleteAccount } from "./bulkDeleteAccounts";
import { watchComapny } from "./companySaga";
import { watchCreateCompany } from "./createCompanySaga";
import { watchDeleteComapny } from "./deleteCompanySaga";
import { watchDeleteDid } from "./deleteDid";
import { watchDelete } from "./deleteUserSaga";
import { watchfetchUserDetails } from "./fetchUserSettings";
import { watchForgetPassword } from "./forgot-password";
import { watchFetchHistory } from "./historySaga";
import { watchInnerReports } from "./innerReports";
import { watchNewUser } from "./newUserSaga";
import { watchGetPlanRequest } from "./planSaga";
import { watchUpdateUserSettings } from "./putUserSettings";
import { watchReports } from "./reports";
import { watchResetPassword } from "./reset-password";
import { watchGetRoles } from "./rolesSaga";
import { watchPostSignout } from "./signout";
import { watchUpdateCompany } from "./updateCompany";
import { watchUpdateDid } from "./updateDid";
import { watchUpdatePassword } from "./updatePassword";
import { watchUpdateUser } from "./updateUserSaga";
import { watchUserDetails } from "./userDetails";
import { watchUserSettings } from "./userSettings";
import { watchUsers } from "./usersSaga";
import { watchVerifyToken } from "./verifyToken";
import { watchPullStripeProducts } from "./pullStripeProducts";
import { watchFetchPlanById } from "./planById";
import { watchSyncPlan } from "./syncPlan";
import { watchSyncAccountStripe } from "./syncAccountStripe";
import { watchCreatePlan } from "./createPlanSaga";
import { watchUpdatePlanById } from "./updatePlanById";
import { watchSubscribeExistingAccount } from "./subscribeExistingAccount";
import { watchHandleExistingAccountStripe } from "./handleExistingAccountStripe";

export default function* rootSaga() {
  yield all([
    ...appSagas,
    watchLogin(),
    watchComapny(),
    watchUsers(),
    watchDeleteComapny(),
    watchUserSettings(),
    watchUpdateUserSettings(),
    watchUpdatePassword(),
    watchUserDetails(),
    watchDeleteDid(),
    watchInnerReports(),
    watchUpdateUser(),
    watchUpdateCompany(),
    watchGetRoles(),
    watchDelete(),
    watchCreateCompany(),
    watchReports(),
    watchFetchHistory(),
    watchNewUser(),
    watchPostSignout(),
    wathcAddDid(),
    watchUpdateDid(),
    watchfetchUserDetails(),
    watchForgetPassword(),
    watchResetPassword(),
    watchVerifyToken(),
    watchBulkDeleteAccount(),
    watchavailabilityUser(),
    watchavailabilityDid(),
    watchGetPlanRequest(),
    watchPullStripeProducts(),
    watchFetchPlanById(),
    watchCreatePlan(),
    watchUpdatePlanById(),
    watchSyncPlan(),
    watchSyncAccountStripe(),
    watchSubscribeExistingAccount(),
    watchHandleExistingAccountStripe(),
  ]);
}
