/* eslint-disable */
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import RequestAppAction from "src/store/slices/appActions";
import styles from "src/components/users-list/userList.module.scss";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import { CompanyStatus } from "src/constants/samples";

const { Text } = Typography;
const { Option } = Select;

interface IEditPlan {
  planByIdData: any;
  setIsEdit: (value: boolean) => void;
}

const EditPlan = ({ planByIdData, setIsEdit }: IEditPlan) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(planByIdData?.status);

  const editColSpan = planByIdData?.isStripeProduct ? 8 : 16;

  const handleSubmit = (values: any) => {
    const {
      status,
      name,
      stripeProductName,
      messageThreshold,
      tier,
    } = values;

    const data: any = {};
    if (status || selectedStatus) data["status"] = selectedStatus ?? status;
    if (name) data["name"] = name;
    if (stripeProductName) data["stripeProductName"] = stripeProductName;
    if (messageThreshold) {
      if (/^\d+$/.test(messageThreshold)) {
        data["messageThreshold"] = parseInt(messageThreshold);
      }
    }
    if (tier) {
      if (/^\d+$/.test(tier)) {
        data["tier"] = parseInt(tier);
      }
    }

    dispatch(
      RequestAppAction.updatePlan({
        id: location.state.id,
        data: data,
        cbSuccess: () => {}
      })
    );

    setIsEdit(false);
  };

  return (
    !planByIdData ? (
      <></>
    ) : (
      <Form
        onFinish={handleSubmit}
        initialValues={{
          ["status"]: planByIdData?.status,
          ["tier"]: planByIdData?.tier,
          ["name"]: planByIdData?.name,
          ["stripeProductName"]: planByIdData?.stripeProductName,
          ["messageThreshold"]: planByIdData?.messageThreshold,
        }}
      >
        <Space
          size={"large"}
          direction="vertical"
          style={{
            borderRadius: "0.625rem",
            width: "100%",
            rowGap: "0",
          }}
        >
          <Row
            gutter={[16, 0]}
            className={`${styles.detail_header} ${styles.detail_body}`}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Data
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Text style={{ textTransform: "none" }}>
                Data in the database
              </Text>
            </Col>
            {planByIdData?.isStripeProduct && (
              <Col span={8}>
                <Text style={{ textTransform: "none" }}>
                  Data in Stripe
                </Text>
              </Col>
            )}
          </Row>
          <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Status
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Form.Item
                name="status"
                initialValue={planByIdData?.status}
                rules={[
                  {
                    required: true,
                    message: "Please Select Status",
                  },
                ]}
                style={{ display: "flex", alignItems: "center", margin: 0 }}
              >
                <Select
                  style={{ width: "18rem" }}
                  value={selectedStatus}
                  onChange={(value: string) => setSelectedStatus(value)}
                  placeholder="Select Status"
                >
                  {Object.values(CompanyStatus).map((status, index) => (
                    <Option key={index} value={status}>
                      {status ? capitalizeFirstLetter(
                        status.toString().toLocaleLowerCase()
                      ) : ""}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Product Tier
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Form.Item
                name="tier"
                rules={[
                  {
                    required: true,
                    message: "Please enter tier",
                  },
                  {
                    pattern: /^\d+$/,
                    message: "Please enter only numbers",
                  },
                ]}
                style={{ display: "flex", alignItems: "center", margin: 0 }}
              >
                <Input placeholder="Enter Tier" />
              </Form.Item>
            </Col>
          </Row>
          {planByIdData?.isStripeProduct && (
            <Row
              gutter={[16, 0]}
              className={styles.detail_body}
            >
              <Col span={7}>
                <Text className={styles.font_style}>
                  Stripe Product ID
                </Text>
              </Col>
              <Col span={8}>
                <Text style={{ textTransform: "none" }}>
                  {planByIdData?.stripeProductId}
                </Text>
              </Col>
            </Row>
          )}
          <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Plan Name
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter plan name",
                  },
                ]}
                style={{ display: "flex", alignItems: "center", margin: 0 }}
              >
                <Input placeholder="Enter Plan Name" />
              </Form.Item>
            </Col>
            {planByIdData?.isStripeProduct && (
              <Col span={8}>
                <Form.Item
                  name="stripeProductName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Stripe Product Name",
                    },
                  ]}
                  style={{ display: "flex", alignItems: "center", margin: 0 }}
                >
                  <Input placeholder="Enter Stripe Product Name" />
                </Form.Item>
              </Col>
            )}
          </Row>
          {/* <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Monthly Rate Price Id
              </Text>
            </Col>
            <Col span={8}>
              <Text style={{ textTransform: "none" }}>
                {planByIdData?.monthlyRatePriceId}
              </Text>
            </Col>
          </Row> */}
          <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Monthly Rate (CAD, dollars)
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Text style={{ textTransform: "none" }}>
                {planByIdData?.monthlyRate}
              </Text>
            </Col>
          </Row>
          {/* <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Message Rate Price Id
              </Text>
            </Col>
            <Col span={8}>
              <Text style={{ textTransform: "none" }}>
                {planByIdData?.messageRatePriceId}
              </Text>
            </Col>
          </Row> */}
          <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Message Rate (CAD, cents)
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Text style={{ textTransform: "none" }}>
                {planByIdData?.messageRate}
              </Text>
            </Col>
          </Row>
          <Row
            gutter={[16, 0]}
            className={styles.detail_body}
          >
            <Col span={7}>
              <Text className={styles.font_style}>
                Message Threshold
              </Text>
            </Col>
            <Col span={editColSpan}>
              <Form.Item
                name="messageThreshold"
                rules={[
                  {
                    required: true,
                    message: "Please enter message threshold",
                  },
                  {
                    pattern: /^\d+$/,
                    message: "Please enter only numbers",
                  },
                ]}
                style={{ display: "flex", alignItems: "center", margin: 0 }}
              >
                <Input placeholder="Enter Message Threshold" />
              </Form.Item>
            </Col>
          </Row>
        </Space>
        <Space
          style={{
            display: "flex",
            padding: "1rem",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
            paddingBottom: "0.5rem",
          }}
        >
          <Button htmlType="submit">Save</Button>
          <Button
            onClick={() => setIsEdit(false)}
            className="outlined_btn"
          >
            Cancel
          </Button>
        </Space>
      </Form>
  )
  );
};

export default EditPlan;
