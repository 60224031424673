import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";

import { RequestTypes } from "../types";
import { pullStripeProductsFailure, pullStripeProductsSuccess } from "../slices/features/plan";

const plansService = new AppService();

function* pullStripeProducts() {
  // const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      plansService.pullStripeProductsRequest,
      baseUrl
    );

    yield put(pullStripeProductsSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(pullStripeProductsFailure({ statusCode, statusText }));
  }
}

export function* watchPullStripeProducts() {
  yield takeLatest(RequestTypes.PULL_STRIPE_PRODUCTS_REQUEST, pullStripeProducts);
}
