import { User } from "./types";

export const usersList: User[] = [
  {
    userName: "Brian P",
    contactName: "Brian Presement",
    email: "brian@test.com",
    additionalEmail: "123@brian.com",
    permissionLevel: "Super Admin (Limited)",
    status: "Active",
    currentMonthUsage: "500/2500",
  },
  {
    userName: "Alice J",
    contactName: "Alice Johnson",
    email: "alice@test.com",
    additionalEmail: "alice@company.com",
    permissionLevel: "Admin",
    status: "Inactive",
    currentMonthUsage: "800/2500",
  },
  {
    userName: "John D",
    contactName: "John Doe",
    email: "john@test.com",
    additionalEmail: "john@company.com",
    permissionLevel: "User",
    status: "Requested",
    currentMonthUsage: "100/2500",
  },
];

export const timezoneOptions = ["EST", "UTC"];

export const sortingBy = ["INBOUND", "OUTBOUND"];

export const categoryOptions = ["ORGANIC", "UNITE", "RESELLER"];

export enum CompanyCategory {
  Organic = "ORGANIC",
  Unite = "UNITE",
  Reseller = "RESELLER",
}

export enum CompanyStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Inactive = "INACTIVE",
}

export const labelStyle = "original";

export const timezones = {
  "America/Los_Angeles": "Pacific Time",
  "America/Dawson": "Dawson, Yukon",
  "America/Edmonton": "Mountain Time",
  "America/Regina": "Saskatchewan",
  "America/Winnipeg": "Central Time",
  "America/Toronto": "Eastern Time",
  "America/St_Johns": "Newfoundland and Labrador",
};

export const DateFormat = "MMMM d, yyyy h:mm aaaa";

export const provinces: any = {
  "AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NL": "Newfoundland and Labrador",
  "NS": "Nova Scotia",
  "NT": "Northwest Territories",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Quebec",
  "SK": "Saskatchewan",
  "YT": "Yukon",
};

export const states: any = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District of columbia",
  "FM": "Federated states of micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New hampshire",
  "NJ": "New jersey",
  "NM": "New mexico",
  "NY": "New york",
  "NC": "North carolina",
  "ND": "North dakota",
  "MP": "Northern mariana islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto rico",
  "RI": "Rhode island",
  "SC": "South carolina",
  "SD": "South dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};
