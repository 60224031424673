import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Accounts } from "src/pages/accounts";
import { AddNewUser } from "src/pages/accounts/add-new-user";
import { CreateNewAccount } from "src/pages/accounts/create-account";
import { TextifyUsers } from "src/pages/accounts/textify-users";
import { Reports } from "src/pages/reports";
import ReportsById from "src/pages/reports/reports-by-id";
import { Plans } from "src/pages/plans";
import PlansById from "src/pages/plans/plans-by-id";
import { CreateNewPlan } from "src/pages/plans/create-plan";
import Settings from "src/pages/settings";

const DashboardContent = () => (
  <Routes>
    <Route path="/*" element={<Navigate to={"/accounts"} />}></Route>
    <Route path="/accounts" element={<Accounts />}></Route>
    <Route path="/accounts/create-new-account" element={<CreateNewAccount />} />
    <Route path="/accounts/:name" element={<TextifyUsers />} />
    <Route path="/accounts/:name/add-new-user" element={<AddNewUser />} />
    <Route path="/reports" element={<Reports />}></Route>
    <Route path="/reports/:id" element={<ReportsById />}></Route>
    <Route path="/plans" element={<Plans />}></Route>
    <Route path="/plans/create-new-plan" element={<CreateNewPlan />} />
    <Route path="/plans/:id" element={<PlansById />}></Route>
    <Route path="/settings" element={<Settings />}></Route>
  </Routes>
);

export default DashboardContent;
