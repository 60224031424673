import { Empty, Form, Spin, Table } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CompanyStatus } from "src/constants/samples";
import { setSearchTerm } from "src/store/slices/features/app";
import styles from "./plansTable.module.scss";

interface CustomTableProps {
  columns: any[];
  data: any[];
  setShowUsersList?: any;
  setShowCreateAccountForm?: any;
  setRowSelected?: any;
  totalPages?: number;
  onChangePage?: any;
  spin?: boolean;
}

const PlansTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  totalPages,
  onChangePage,
  spin,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updatedColumns = columns.map((column: any) => {
    if (column.dataIndex === "status") {
      return {
        ...column,
        render: (text: string, plan: any) => (
          <span
            style={{ padding: "0.2rem" }}
            className={`${styles.flex_align_center}  ${
              plan.status !== CompanyStatus.Active
                ? styles.text_secondary
                : ""
            }`}
          >
            <div
              className={`${styles.dot} ${
                plan.didstatus === CompanyStatus.Active
                  ? styles.bg_success
                  : styles.bg_secondary
              }`}
            />
            <div />
            {text}
          </span>
        ),
      };
    }

    if (column.dataIndex === "username") {
      return {
        ...column,
        render: (text: string) => (
          <span
            style={{ padding: "0.2rem" }}
            className={`${styles.flex_align_center} `}
          >
            {text || "-"}
          </span>
        ),
      };
    }

    if (column.dataIndex === "usageCount") {
      return {
        ...column,
        render: (text: string) => (
          <span
            style={{ padding: "0.2rem" }}
            className={`${styles.flex_align_center} `}
          >
            {text || "-"}
          </span>
        ),
      };
    }

    return column;
  });

  const onPlanClick = (plan: any) => {
    if (plan.id) {
      dispatch(setSearchTerm(""));
      navigate(`/plans/${plan.id}`, { state: { id: plan.id } });
    }
  };

  return (
    <Form
      style={{
        display: "flex",
        width: "100%",
        position: "relative",
      }}
    >
      <Table
        locale={{
          emptyText: !spin ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
          ) : (
            <Spin></Spin>
          ),
        }}
        style={{
          height: "100%",
          width: "100%",
        }}
        pagination={{
          total: totalPages,
          onChange: (page) => {
            onChangePage(page);
          },
          showSizeChanger: false,
        }}
        className={styles.table_wrapper}
        columns={updatedColumns}
        dataSource={data}
        rowKey={(plan) => `plan-row-${plan.id}`}
        onRow={(plan) => {
          return {
            onClick: () => {
              onPlanClick(plan);
            },
            style: { cursor: plan.id ? "pointer" : "default" },
          };
        }}
      />
    </Form>
  );
};

export default PlansTable;
