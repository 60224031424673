import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";

import { RequestTypes } from "../types";
import { handleExistingAccountStripeFailure, handleExistingAccountStripeSuccess } from "../slices/features/company";

const handleExistingAccountStripeService = new AppService();

function* handleExistingAccountStripe() {
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      handleExistingAccountStripeService.handleExistingAccountStripeRequest,
      baseUrl,
    );

    yield put(handleExistingAccountStripeSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(handleExistingAccountStripeFailure({ statusCode, statusText }));
  }
}

export function* watchHandleExistingAccountStripe() {
  yield takeLatest(
    RequestTypes.HANDLE_EXISTING_ACCOUNT_STRIPE_REQUEST,
    handleExistingAccountStripe
  );
}
