import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";

import { RequestTypes } from "../types";
import { syncAccountStripeFailure, syncAccountStripeSuccess } from "../slices/features/userDetails";

const userDetailService = new AppService();

function* syncAccountStripe(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      userDetailService.syncAccountStripeRequest,
      baseUrl,
      id
    );

    console.log(response)

    yield put(syncAccountStripeSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(syncAccountStripeFailure({ statusCode, statusText }));
  }
}

export function* watchSyncAccountStripe() {
  yield takeLatest(RequestTypes.SYNC_ACCOUNT_STRIPE_REQUEST, syncAccountStripe);
}
