import { combineReducers } from "redux";
import { newUsersReducer } from "./addNewUser";
import { appFeatureReducer } from "./app";
import { LoginReducer } from "./auth";
import { availabilityReducer } from "./availablity";
import { companyReducer } from "./company";
import { createCompanyReducer } from "./createCompany";
import { didReducer } from "./did";
import { historyReducer } from "./history";
import { reportByIdReducer } from "./innerReports";
import { planReducer } from "./plan";
import { reportsReducer } from "./reports";
import { resetPasswordReducer } from "./reset-password";
import { rolesReducer } from "./roles";
import { signoutReducer } from "./signout";
import { updateCompanyReducer } from "./updateCompany";
import { passwordReducer } from "./updatePassword";
import { userDetailsReducer } from "./userDetails";
import { userSettingsReducer } from "./userSettings";
import { usersReducer } from "./users";
import { verifyTokenReducer } from "./verifyToken";
import { planByIdReducer } from "./planById";
import { subscribeExistingAccountReducer } from "./subscribeExistingAccount";

const featuresReducer = combineReducers({
  app: appFeatureReducer,
  company: companyReducer,
  updateCompany: updateCompanyReducer,
  login: LoginReducer,
  passwrod: passwordReducer,
  userSettings: userSettingsReducer,
  users: usersReducer,
  did: didReducer,
  userDetails: userDetailsReducer,
  roles: rolesReducer,
  createCompany: createCompanyReducer,
  reports: reportsReducer,
  history: historyReducer,
  newUser: newUsersReducer,
  signout: signoutReducer,
  reportById: reportByIdReducer,
  verifyToken: verifyTokenReducer,
  resetPassword: resetPasswordReducer,
  availability: availabilityReducer,
  plan: planReducer,
  planById: planByIdReducer,
  subscribeExistingAccount: subscribeExistingAccountReducer,
  /**
   * More feature reducers will be added here
   */
});

export { featuresReducer };
