/* eslint-disable */
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RequestAppAction from "src/store/slices/appActions";
import { getPlanByIdData, getPlanByIdLoadingState } from "src/store/selectors/features/planById";
import styles from "src/components/users-list/userList.module.scss";
import EditPlan from "./EditPlan";
import { ROLES } from "src/constants/roles";

const { Text } = Typography;

const PlansById: React.FC = () => {
  const dispatch = useDispatch();
  const planByIdData: any = useSelector(getPlanByIdData);
  const isLoading: any = useSelector(getPlanByIdLoadingState);
  const location = useLocation();
  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const colSpan = planByIdData?.isStripeProduct ? 8 : 16;

  useEffect(() => {
    dispatch(
      RequestAppAction.fetchPlanById({
        id: location.state.id,
      })
    );
  }, []);

  useEffect(() => {
    console.log(planByIdData);
  }, [planByIdData]);

  const handleSync = () => {
    Modal.confirm({
      title: "Are you sure you want to sync?",
      content: "This action cannot be undone.",
      onOk() {
        dispatch(
          RequestAppAction.syncPlan({
            id: location.state.id,
          })
        );
      },
    });
  };

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
          spinning={isLoading}
        ></Spin>
        <div style={{ height: "100%", overflow: "hidden", width: "100%" }}>
          <Space
            direction="vertical"
            size={"large"}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
            className={styles.user_list_wrapper}
          >
            <Space
              direction="vertical"
              style={{
                display: "flex",
                position: "relative",
                marginTop: "-1rem",
                paddingBottom: 0,
                paddingRight: 20,
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
              className={styles.user_list_content_headerings_wrapper}
            >
              {!isLoading && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    width: "100%",
                    height: "60vh",
                  }}
                >
                  {!isEdit && (
                    <Space
                      direction="vertical"
                      size={"large"}
                      style={{
                        position: "relative",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      className={styles.user_list_wrapper_header}
                    >
                      <Space
                        size={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {planByIdData?.isStripeProduct && (
                          <Button
                            icon={<ReloadOutlined />}
                            onClick={handleSync}
                            disabled={ROLES.SUPERADMINFULL?.toLowerCase() !== loggedIn?.role?.toLowerCase()}
                          >
                            Re-Sync Plan Data with Stripe Product
                          </Button>
                        )}
                        <Space
                          style={{
                            marginTop: "-0.8rem",
                            display: "flex",
                            justifyContent: "end"
                          }}
                          size={12}
                        >
                          {ROLES.SUPERADMINFULL?.toLowerCase() === loggedIn?.role?.toLowerCase()
                            && (
                              <Button
                                shape="circle"
                                onClick={() => setIsEdit(true)}
                                icon={<EditOutlined />}
                                disabled={ROLES.SUPERADMINFULL?.toLowerCase() !== loggedIn?.role?.toLowerCase()}
                              ></Button>
                            )
                          }
                          {/* <Button
                            shape="circle"
                            title="title"
                            // onBtnClick={() => {
                            //   handleDeleteCompany();
                            //   isEdit(false);
                            // }}
                            icon={<DeleteOutlined />}
                          ></Button> */}
                        </Space>
                      </Space>
                    </Space>
                  )}
                  {!planByIdData?.isStripeProduct ? (
                    <Space style={{ marginBottom: "1rem" }}>
                      <ExclamationCircleOutlined style={{ color: "red" }} />
                      <Text style={{ color: "red", textTransform: "none" }}>
                        Plan is not connected to the Stripe.
                      </Text>
                    </Space>
                  ) : !planByIdData?.isValid ? (
                    <Space style={{ marginBottom: "1rem" }}>
                      <ExclamationCircleOutlined style={{ color: "red" }} />
                      <Text style={{ color: "red", textTransform: "none" }}>
                        Plan data in the database doesn't match with the Stripe product data.
                        Click the button to re-sync the data or change the Stripe Product ID.
                      </Text>
                    </Space>
                  ) : (
                    <></>
                  )}
                  <Space
                    size={"large"}
                    direction="vertical"
                    style={{
                      borderRadius: "0.625rem",
                      width: "100%",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                    className={styles.plan_details_wrapper}
                  >
                    {ROLES.SUPERADMINFULL?.toLowerCase() === loggedIn?.role?.toLowerCase()
                      && isEdit
                      ? (
                        <EditPlan planByIdData={planByIdData} setIsEdit={setIsEdit} />
                      ) : (
                        <>
                          <Row
                            gutter={[16, 0]}
                            className={`${styles.detail_header} ${styles.detail_body}`}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Data
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                Data in the database
                              </Text>
                            </Col>
                            {planByIdData?.isStripeProduct && (
                              <Col span={8}>
                                <Text style={{ textTransform: "none" }}>
                                  Data in Stripe
                                </Text>
                              </Col>
                            )}
                          </Row>
                          <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Status
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.status}
                              </Text>
                            </Col>
                          </Row>
                          <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Tier
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.tier}
                              </Text>
                            </Col>
                            {planByIdData?.isStripeProduct && (
                              <Col span={8}>
                                <Text style={{ textTransform: "none" }}>
                                  -
                                </Text>
                              </Col>
                            )}
                          </Row>
                          {planByIdData?.isStripeProduct && (
                            <Row
                              gutter={[16, 0]}
                              className={styles.detail_body}
                            >
                              <Col span={7}>
                                <Text className={styles.font_style}>
                                  Stripe Product ID
                                </Text>
                              </Col>
                              <Col span={colSpan}>
                                <Text style={{ textTransform: "none" }}>
                                  {planByIdData?.stripeProductId}
                                </Text>
                              </Col>
                            </Row>
                          )}
                          <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Plan Name
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.name}
                              </Text>
                            </Col>
                            {planByIdData?.isStripeProduct && (
                              <Col span={8}>
                                <Text style={{ textTransform: "none" }}>
                                  {planByIdData?.stripeProductName}
                                </Text>
                              </Col>
                            )}
                          </Row>
                          {/* <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Monthly Rate Price Id
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.monthlyRatePriceId}
                              </Text>
                            </Col>
                          </Row> */}
                          <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Monthly Rate (CAD, dollars)
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.monthlyRate}
                              </Text>
                            </Col>
                          </Row>
                          {/* <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Message Rate Price Id
                              </Text>
                            </Col>
                            <Col span={8}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.messageRatePriceId}
                              </Text>
                            </Col>
                          </Row> */}
                          <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Message Rate (CAD, cents)
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.messageRate}
                              </Text>
                            </Col>
                          </Row>
                          <Row
                            gutter={[16, 0]}
                            className={styles.detail_body}
                          >
                            <Col span={7}>
                              <Text className={styles.font_style}>
                                Message Threshold
                              </Text>
                            </Col>
                            <Col span={colSpan}>
                              <Text style={{ textTransform: "none" }}>
                                {planByIdData?.messageThreshold}
                              </Text>
                            </Col>
                          </Row>
                        </>
                      )
                    }
                  </Space>
                </div>
              )}
            </Space>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default PlansById;
