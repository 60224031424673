import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";

import { RequestTypes } from "../types";
import { fetchPlanByIdFailure, fetchPlanByIdSuccess } from "../slices/features/planById";

const plansService = new AppService();

function* fetchPlanById(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      plansService.fetchPlanByIdRequest,
      baseUrl,
      id
    );

    console.log(response)

    yield put(fetchPlanByIdSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;

    toast.error(Array.isArray(message) ? message[0] : message);

    yield put(fetchPlanByIdFailure({ statusCode, statusText }));
  }
}

export function* watchFetchPlanById() {
  yield takeLatest(RequestTypes.FETCH_PLAN_BY_ID_REQUEST, fetchPlanById);
}
