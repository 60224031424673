/* eslint-disable */
import {
  BarChartOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  SettingOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";

import { Layout, Menu, MenuProps, Spin, Typography, theme } from "antd";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardContent from "src/components/dashboard-content";
import Header from "src/components/header";
import { getSignoutLoadingState } from "src/store/selectors/features/signout";
import { getUsersData } from "src/store/selectors/features/users";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import DashboardHeader from "../../components/dashboard-header";
const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  target?: string
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    target,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Accounts", "1", <ProfileOutlined />, null, "/accounts"),
  getItem("Reports", "2", <BarChartOutlined />, null, "/reports"),
  getItem("Plans", "3", <ShoppingOutlined />, null, "/plans"),
  getItem("Settings", "4", <SettingOutlined />, null, "/settings"),
];

const { Title } = Typography;

const Dashboard: React.FC = () => {
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(["1"]);
  const signoutLoading = useSelector(getSignoutLoadingState);
  const ref: any = useRef();
  const userData: any = useSelector(getUsersData);

  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();

  let headerTitle: string | string[] = location.pathname.split("/");

  if (headerTitle.length === 2 && headerTitle[1]) {
    [, headerTitle] = headerTitle;
  } else if (headerTitle.length === 3 && headerTitle[2]) {
    [, , headerTitle] = headerTitle;
  } else if (headerTitle.length === 4 && headerTitle[2]) {
    [, , , headerTitle] = headerTitle;
  } else {
    headerTitle = "Accounts";
  }

  if (headerTitle === "index.html") {
    headerTitle = "Accounts";
  }

  headerTitle = headerTitle.replace(/-/g, " ");

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleClickItem = (key: any) => {
    if (key == 1) {
      navigate("/accounts");
      dispatch(setSearchTerm(""));
    } else if (key == 2) {
      navigate("/reports");
      dispatch(setSearchTerm(""));
    } else if (key == 3) {
      navigate("/plans");
      dispatch(setSearchTerm(""));
    } else if (key == 4) {
      navigate("/settings");
      dispatch(setSearchTerm(""));
    }
  };

  function clearLocalStorage(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("user");
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  function onPressLogout() {
    if (!loggedIn) navigate("/login");
    else {
      dispatch(
        RequestAppAction.userSignOut({
          cbSuccess: () => {
            clearLocalStorage()
              .then(() => {
                navigate("/login");
              })
              .catch(() => {
                toast.error("Something went wrong");
              });
          },
        })
      );
    }
  }

  useEffect(() => {
    // ref.current.scrollTo(0, 0);
  }, [location.pathname]);

  const pattern = /^\/accounts\/[^/]+$/;

  const SelectedItem = () => {
    const path = window.location.pathname;

    items.map((i: any) => {
      if (i?.target === path) {
        setSelectedKey([i?.key]);
      }
    });
  };

  useEffect(() => {
    SelectedItem();
  }, [window.location.pathname]);

  const patternUserName = /^\/accounts\/\d+$/;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
      }}
      className="dashboard_wrapper"
    >
      <Spin
        style={{
          height: "100%",
          position: "absolute",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        spinning={signoutLoading}
      />
      <Header
        user={loggedIn?.username || loggedIn?.email.split("@")[0]}
        onLogout={onPressLogout}
        onClick={() => navigate("/")}
      />
      <Layout>
        <Sider
          trigger={
            <Title level={5} style={{ padding: 0, margin: 0 }}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}{" "}
            </Title>
          }
          collapsible
          collapsed={collapsed}
          theme="light"
          onCollapse={(value) => setCollapsed(value)}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            theme="light"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
            selectedKeys={selectedKey}
            selectable
            onClick={({ key }) => handleClickItem(key)}
          />
        </Sider>
        <Layout
          style={{
            display: "flex",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <DashboardHeader
            title={
              patternUserName.test(location.pathname)
                ? userData?.name
                : headerTitle
            }
          />
          <Layout
            style={{
              height: "100%",
              overflowX: "hidden",
              overflowY:
                params
                && params["*"]
                && (params["*"] === "accounts" || params["*"] === "reports")
                  ? "hidden"
                  : "scroll",
            }}
          >
            <DashboardContent />
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default Dashboard;
