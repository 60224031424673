export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const turnicatetext = (maxLength: number, text: string) => {
  const truncatedText =
    text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  return truncatedText;
};

export const formatAddress = (
  postalCode: string,
  country: string,
  province?: string,
  state?: string,
  city?: string,
  line1?: string,
  line2?: string,
) => {
  const address = {
    country: country ?? '',
    state: province ? province : state ? state : '',
    city: city ?? '',
    line1: line1 ?? '',
    line2: line2 ?? '',
    postalCode: postalCode,
  };

  return address;
}

export const formatAddressToString = (
  postalCode: string,
  country: string,
  state?: string,
  city?: string,
  line1?: string,
  line2?: string,
): string => {
  const addressParts = [
    line2,
    line1,
    city,
    state,
    country,
    postalCode
  ].filter(part => part && part.trim() !== "");

  return addressParts.join(", ");
}

export const countryCodeToCurrency: any = {
  "CA": "CAD",
  "US": "USD",
};
