import type { FormInstance, SelectProps } from "antd";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import {
  CompanyStatus,
  categoryOptions,
  labelStyle,
} from "src/constants/samples";
import { getPlanData } from "src/store/selectors/features/plans";
import RequestAppAction from "src/store/slices/appActions";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import { provinces, states } from "src/constants/samples";
import styles from "../createAccount.module.scss";
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { Option } = Select;

interface Props {
  form?: FormInstance;
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
}

const { Text } = Typography;

const CreateAccountForm = ({
  form,
  selectedCountry,
  setSelectedCountry,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const plans: any = useSelector(getPlanData);
  const [activePlans, setActivePlans] = useState(plans);
  const [checked, setIsChecked] = useState(true);
  const [isUnite, setIsUnite] = useState(false);

  const { options: timezoneOptions } = useTimezoneSelect({
    labelStyle,
    timezones: allTimezones,
  });

  const timezoneList = () => {
    const list = [];

    for (let i = 0; i < timezoneOptions.length; i++) {
      list.push({
        key: `timezone-${i}`,
        label: timezoneOptions[i].label,
        value: timezoneOptions[i].value
      });
    }

    return list;
  };

  const planList = () => {
    const list = [];

    if (activePlans && activePlans.length > 0){
      for (let i = 0; i < activePlans.length; i++) {
        const productName = capitalizeFirstLetter(activePlans[i].stripeProductName || "")
        list.push({ key: i, label: productName, value: activePlans[i].id });
      }
    }

    return list;
  }

  const getOptionsList = (options: any) => {
    const list = [];

    if (options && Object.keys(options).length > 0) {
      for (const key in options) {
        list.push({
          key,
          label: options[key as keyof any], value: key
        });
      }
    }

    return list;
  }

  const timeZoneOptions: SelectProps['options'] = timezoneList();
  const planOptions: SelectProps['options'] = planList();

  const countryOptions: SelectProps['options'] = [
    { key: "CA", label: t("geoLocation.countries.CA"), value: "CA" },
    { key: "US",label: t("geoLocation.countries.US"), value: "US" },
  ];

  const provinceOptions: SelectProps['options'] = getOptionsList(provinces);
  const stateOptions: SelectProps['options'] = getOptionsList(states);

  useEffect(() => {
    dispatch(initiateFetchRoles());
    form?.setFieldValue("billedExternally", true);
    dispatch(
      RequestAppAction.fetchPlan({
        // cbSuccess: (res: any) => {
        //   if (form) {
        //     form.setFieldValue("plan", res?.data[0]?.id);
        //   }
        // },
      })
    );
  }, []);

  useEffect(() => {
    if (plans && plans.length > 0) {
      let filteredPlans = plans;

      if (checked || isUnite) {
        filteredPlans = plans.filter((el: any) => {
          return (
            el.status === CompanyStatus.Active ||
            el.status === CompanyStatus.Pending
          );
        });
      } else {
        filteredPlans = plans.filter((el: any) => {
          return el.status === CompanyStatus.Active;
        });

        // Can only select the free plan without the payment method
        filteredPlans = filteredPlans.filter((el: any) => {
          return el.monthlyRate === "0";
        });
      }

      setActivePlans(filteredPlans);
    }
  }, [plans, checked, isUnite]);

  const onChangeCategory = (value: any) => {
    if (value === categoryOptions[1]) {
      setIsUnite(true);
      setIsChecked(true);
      form?.setFieldValue("billedExternally", true);
    } else {
      setIsUnite(false);
    }
  };

  const onChangeCountry = (value: any) => {
    setSelectedCountry(value);

    if (value === "CA") {
      form?.resetFields(["state"]);
    } else if (value === "US") {
      form?.resetFields(["province"]);
    }
  };

  return (
    <div className={styles.account_details}>
      <Space className={styles.account_details_header} align="center">
        <Title level={5} style={{ margin: 0 }}>
          Account Details
        </Title>
      </Space>

      <div className={styles.form_wrapper}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[{ required: true, message: "Please enter company name" }]}
            >
              <Input placeholder="Enter company name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="category"
              label="Category"
              initialValue={categoryOptions[0]}
              rules={[{ required: true, message: "Category" }]}
            >
              <Select placeholder="Select Category" onChange={onChangeCategory}>
                {categoryOptions.map((category, index) => (
                  <Option key={index} value={category}>
                    {capitalizeFirstLetter(
                      category?.toString()?.toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="companyStatus"
              label="Company Status"
              initialValue={capitalizeFirstLetter(
                CompanyStatus.Active.toLocaleLowerCase().toString()
              )}
              rules={[{ required: true, message: "Company Status" }]}
            >
              <Select placeholder="active">
                {Object.values(CompanyStatus).map((status, index) => (
                  <Option key={index} value={status}>
                    {capitalizeFirstLetter(
                      status?.toString()?.toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {!isUnite && (
            <Col span={6}>
              <Form.Item
                name="country"
                label="Country"
                initialValue={selectedCountry}
                rules={[{ required: true, message: "Country is required" }]}
              >
                <Select
                  placeholder="Select Country"
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={onChangeCountry}
                />
              </Form.Item>
            </Col>
          )}

          {!isUnite && (
            <Col span={6}>
              {selectedCountry === "CA" ? (
                <Form.Item
                  name="province"
                  label="Province (Canada)"
                  rules={[
                    {
                      required: true,
                      message: "Province or Territory is required for Canada"
                    }
                  ]}
                >
                  <Select
                    placeholder="Select Province or Territory"
                    options={provinceOptions}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="state"
                  label="State (US)"
                  rules={[
                    {
                      required: true,
                      message: "State is required for US"
                    }
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    options={stateOptions}
                  />
                </Form.Item>
              )}
            </Col>
          )}
          <Col span={isUnite ? 12 : 6}>
            <Form.Item
              name="timezone"
              label="Timezone"
              rules={[{ required: true, message: "Please select timezone" }]}
            >
              <Select
                placeholder="Select Timezone"
                options={timeZoneOptions}
              />
            </Form.Item>
          </Col>
          {!isUnite && (
            <Col span={6}>
              <Form.Item
                name="postalCode"
                label="Postal Code"
                rules={[{ required: true, message: "Postal Code is required" }]}
              >
                <Input placeholder="Enter postal code" />
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <Form.Item
              name="plan"
              label={`Plan ${isUnite ? "" : "(only free plan without payment method)"}`}
              // initialValue={planOptions[0]}
              rules={[{ required: true, message: "Subscription plan is required" }]}
            >
              <Select
                placeholder="Select a Plan"
                options={planOptions}
              />
            </Form.Item>
          </Col>
          {!isUnite && (
            <Col span={12}>
              <Form.Item
                name="billingEmailAddress"
                label="Billing Email Address (Optional)"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  }
                ]}
              >
                <Input placeholder="Enter Billing Email Address (Optional)" />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              name="notes"
              label="Notes"
              rules={[{ message: "Enter Details" }]}
            >
              <Input placeholder="Enter notes" />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              style={{ margin: 0 }}
              valuePropName="checked"
              name="billedExternally"
            >
              <Checkbox
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
                disabled={isUnite}
                checked={checked}
              >
                <Text>Billed externally</Text>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateAccountForm;
