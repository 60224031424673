import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React from "react";
import { CompanyStatus } from "src/constants/samples";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import styles from "../createAccount.module.scss";
import Switch from "src/components/switch";

const { Title } = Typography;
const { Option } = Select;

interface ICreatePlanForm {
  isStripePlan: boolean;
  setIsStripePlan: (value: boolean) => void;
}

const CreatePlanForm: React.FC<ICreatePlanForm> = ({
  isStripePlan,
  setIsStripePlan
}) => {
  return (
    <div className={styles.account_details}>
      <Space className={styles.account_details_header} align="center">
        <Title level={5} style={{ margin: 0 }}>
          Plan Details
        </Title>
      </Space>

      <div className={styles.form_wrapper}>
        <Switch
          checked={isStripePlan}
          onChange={(e) => setIsStripePlan(e)}
          label="Connect to Stripe:"
          checkedText="Yes"
          unCheckedText="No"
        />
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              name="name"
              label="Plan Name"
              rules={[{ required: true, message: "Please enter plan name" }]}
            >
              <Input placeholder="Enter plan name" />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={6}>
            <Form.Item
              name="status"
              label="Plan Status"
              initialValue={CompanyStatus.Active}
              rules={[{ required: true, message: "Plan Status" }]}
            >
              <Select placeholder="active">
                {Object.values(CompanyStatus).map((status, index) => (
                  <Option key={index} value={status}>
                    {capitalizeFirstLetter(
                      status?.toString()?.toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={6}>
            <Form.Item
              name="tier"
              label="Plan Tier"
              initialValue={1}
              rules={[{ required: true, message: "Tier is required" }]}
            >
              <InputNumber
                placeholder="Enter tier of the plan"
                min={1}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              name="monthlyRate"
              label="Monthly Rate"
              initialValue={0}
            >
              <InputNumber<string>
                placeholder="Enter monthly rate of the plan"
                min="0"
                step="0.00"
                addonBefore="CAD"
                addonAfter="$"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              name="messageRate"
              label="Message Rate"
              initialValue={0}
            >
              <InputNumber<string>
                placeholder="Enter message rate of the plan"
                min="0"
                step="0.00"
                addonBefore="CAD"
                addonAfter="¢"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={6}>
            <Form.Item
              name="messageThreshold"
              label="Message Threshold"
              initialValue={0}
            >
              <InputNumber
                placeholder="Enter message threshold of the plan"
                min={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreatePlanForm;
