import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  subscribeState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  subscribeData: null,
};

export const subscribeExistingAccountSlice = createSlice({
  name: "subscribeExistingAccount",
  initialState: INITIAL_STATE,
  reducers: {
    subscribeExistingAccount: (state, { payload }) => {
      state.subscribeState.isLoading = true;
      payload;
    },
    subscribeExistingAccountSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.subscribeState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      subscribeData: action.payload?.data,
    }),
    subscribeExistingAccountFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.subscribeState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearStripeSubscription: (state) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.subscribeState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
      },
      subscribeData: null,
    }),
  },
});

export const {
  subscribeExistingAccount,
  subscribeExistingAccountFailure,
  subscribeExistingAccountSuccess,
  clearStripeSubscription,
} = subscribeExistingAccountSlice.actions;
export const subscribeExistingAccountReducer = subscribeExistingAccountSlice.reducer;
