import React from "react";
import CreatePlan from "src/components/create-plan";

export const CreateNewPlan: React.FC = () => {
  return (
    <div style={{ height: "100%" }}>
      <CreatePlan />
    </div>
  );
};
