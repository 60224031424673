import { PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Modal, Space, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Limit } from "src/constants/endpoints";
import { CompanyStatus, DateFormat } from "src/constants/samples";
import { AccountsOrderBy, DataType, Order, SortAccountsOrder } from "src/constants/types";
import { getSearchTerm } from "src/store/selectors/features/app";
import {
  getCompanyData,
  getCompanyLoadingState,
} from "src/store/selectors/features/company";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import { fetchCompaniesData } from "src/store/slices/features/company";
import Colors from "src/themes/colors";
import Button from "../../components/button";
import CustomTable from "../../components/custom-table";
import Switch from "../../components/switch";
import { orderByOrder } from "src/constants/orderBy";

interface dataParams {
  page?: number;
  limit: string;
  search?: string;
  status?: string;
  showStripeInvalid?: boolean;
  order?: SortAccountsOrder;
  cbSuccess?: () => void;
}

export const Accounts: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const companyData: any = useSelector(getCompanyData);
  const searchTerm = useSelector(getSearchTerm);
  const loading: any = useSelector(getCompanyLoadingState);

  const [isLoading, setIsLoading] = useState(true);
  const [showActive, setShowActive] = useState(false);
  const [showStripeInvalid, setShowStripeInvalid] = useState(false);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [rowSelected, setRowSelected] = useState<boolean>(false);

  const [accountsOrder, setAccountsOrder] = useState<SortAccountsOrder>({
    orderBy: "updatedAt",
    orderByStripe: false,
    order: "desc",
  });

  useEffect(() => {
    const data: dataParams = {
      page: 1,
      limit: Limit,
      search: encodeURIComponent(searchTerm),
      showStripeInvalid: showStripeInvalid,
      status: CompanyStatus.Active,
      order: accountsOrder,
      cbSuccess: () => {
        setIsLoading(false);
      },
    };

    if (!showActive) {
      data["status"] = CompanyStatus.Active;
    }

    dispatch(fetchCompaniesData(data));
  }, [showActive, showStripeInvalid, accountsOrder]);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      const data: dataParams = {
        page: 1,
        limit: Limit,
        search: encodeURIComponent(searchTerm),
        showStripeInvalid: showStripeInvalid,
        order: accountsOrder,
        cbSuccess: () => {
          setIsLoading(false);
        },
      };

      if (!showActive) {
        data["status"] = CompanyStatus.Active;
      }

      dispatch(fetchCompaniesData(data));
    }, 800);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (companyData && companyData.items && companyData.items.length) {
      const convertedData = companyData.items.map((item: any) => ({
        id: item.id,
        companyname: item.name,
        monthlyusage: `${item?.usageCount || 0}/${item?.usageLimit || 0}`, // You can add logic here to calculate monthly usage.
        category: item.category,
        status: item.status,
        created: format(new Date(item.createdAt), DateFormat),
        lastactivity: format(new Date(item.updatedAt), DateFormat),
        isStripeInvalid: item.billedExternally ? undefined : !!(!item.stripeCustomerId || !item.stripeProductId || !item.subscriptionId),
      }));

      setTableData(convertedData);
    } else {
      setTableData([]);
    }
  }, [companyData]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  // const handleExistingAccounts = () => {
  //   dispatch(
  //     RequestAppAction.handleExistingAccountStripe({
  //       cbSuccess: (res: any) => {
  //         console.log(res);
  //       },
  //       cbFailure: (res: any) => {
  //         console.log(res);
  //       },
  //     })
  //   );
  // };


  const getOrder = (
    orderBy: AccountsOrderBy = "updatedAt",
    orderByStripe: boolean,
    order?: Order,
  ) => {
    if (accountsOrder) {
      if (
        orderBy !== accountsOrder?.orderBy ||
        orderByStripe !== accountsOrder?.orderByStripe
      ) {
        return order || "desc";
      } else {
        return accountsOrder?.order === "desc" ? "asc" : "desc";
      }
    }

    return order || "desc";
  };

  const getAccountsOrder = (
    orderBy: AccountsOrderBy = "updatedAt",
    orderByStripe: boolean,
    order?: Order,
  ) => {
    setAccountsOrder({
      orderBy,
      orderByStripe,
      order: getOrder(orderBy, orderByStripe, order),
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 70,
    },
    {
      title: "Company Name",
      dataIndex: "companyname",
      key: "companyname",
      fixed: "left",
      // sorter: (a: any, b: any) => {
      //   if (!a?.companyname && !b?.companyname) return 1;
      //   if (!a?.companyname) return -1;
      //   if (!b?.companyname) return 1;
      //   return a.companyname < b.companyname ? 1 : a.companyname > b.companyname ? -1 : 0;
      // },
      // sortDirections: ["ascend", "descend", "ascend"],
      onHeaderCell: () => {
        return {
          className: "table-header",
          onClick: () => {
            getAccountsOrder("name", false, orderByOrder["name"]);
          },
        };
      },
      render: (text, record) => ({
        props: {
          style: {
            color:
              record.status === CompanyStatus.Active
                ? Colors.Secondary
                : "inherit",
          },
        },
        children: <span>{text}</span>,
      }),
      width: 180,
    },
    {
      title: "Monthly Usage",
      dataIndex: "monthlyusage",
      key: "monthlyusage",
      // sorter: (a: any, b: any) => a.monthlyusage.length - b.monthlyusage.length,
      // sortDirections: ["ascend", "descend", "ascend"],
      width: 150,
      render: (text) => ({
        children: <span>{text || "-"}</span>,
      }),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      // sorter: (a: any, b: any) => {
      //   if (!a?.category && !b?.category) return 1;
      //   if (!a?.category) return -1;
      //   if (!b?.category) return 1;
      //   return a.category < b.category ? 1 : a.category > b.category ? -1 : 0;
      // },
      // sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
      onHeaderCell: () => {
        return {
          className: "table-header",
          onClick: () => {
            getAccountsOrder("category", false, orderByOrder["category"]);
          },
        };
      },
      render: (text) => ({
        children: <span>{text || "-"}</span>,
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onHeaderCell: () => {
        return {
          className: "table-header",
          onClick: () => {
            getAccountsOrder("status", false, orderByOrder["status"]);
          },
        };
      },
      // sorter: (a: any, b: any) => {
      //   if (!a?.status && !b?.status) return 1;
      //   if (!a?.status) return -1;
      //   if (!b?.status) return 1;
      //   return a.status < b.status ? 1 : a.status > b.status ? -1 : 0;
      // },
      width: 100,
    },
    {
      title: "Stripe",
      dataIndex: "isStripeInvalid",
      key: "isStripeInvalid",
      onHeaderCell: () => {
        return {
          className: "table-header",
          onClick: () => {
            getAccountsOrder("updatedAt", true, orderByOrder["updatedAt"]);
          },
        };
      },
      // sorter: (a: any, b: any) => {
      //   if (a?.isStripeInvalid === undefined && !b?.isStripeInvalid === undefined) return 1;
      //   if (a?.isStripeInvalid === undefined) return -1;
      //   if (b?.isStripeInvalid === undefined) return 1;
      //   return a?.isStripeInvalid === b?.isStripeInvalid ? 0 : a?.isStripeInvalid ? -1 : 1;
      // },
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "created",
      key: "created",
      onHeaderCell: () => {
        return {
          className: "table-header",
          onClick: () => {
            getAccountsOrder("createdAt", false, orderByOrder["createdAt"]);
          },
        };
      },
      // sorter: (a: any, b: any) => {
      //   if (!a?.created && !b?.created) return 1;
      //   if (!a?.created) return -1;
      //   if (!b?.created) return 1;
      //   return a.created < b.created ? 1 : a.created > b.created ? -1 : 0;
      // },
    },
    {
      title: "Last Activity",
      dataIndex: "lastactivity",
      key: "lastactivity",
      onHeaderCell: () => {
        return {
          className: "table-header",
          onClick: () => {
            getAccountsOrder("updatedAt", false, orderByOrder["updatedAt"]);
          },
        };
      },
      // sorter: (a: any, b: any) => {
      //   if (!a?.lastactivity && !b?.lastactivity) return 1;
      //   if (!a?.lastactivity) return -1;
      //   if (!b?.lastactivity) return 1;
      //   return a.lastactivity < b.lastactivity ? 1 : a.lastactivity > b.lastactivity ? -1 : 0;
      // },
    },
  ];

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        width: "100%",
      }}
      className="layout_padding"
    >
      <Spin
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          zIndex: loading || isLoading ? 1 : 0,
        }}
        spinning={loading || isLoading}
      />
      <div
        style={{
          opacity: isLoading ? 0 : 1,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-1.4rem",
          }}
        >
          <Button
            title="Create New Account"
            icon={<PlusCircleOutlined />}
            onBtnClick={() => navigate("/accounts/create-new-account")}
          />

          {/* <Button
            title="Handle Existing Accounts"
            icon={<PlusCircleOutlined />}
            onBtnClick={handleExistingAccounts}
          /> */}

          <Switch
            onChange={(val) => {
              setShowActive(val);
            }}
            checked={showActive}
            label="Show Inactive"
          />
          <Switch
            onChange={(val) => {
              setShowStripeInvalid(val);
            }}
            checked={showStripeInvalid}
            label="Show Stripe Invalid"
          />
          {rowSelected && (
            <Button
              onBtnClick={() => {
                Modal.confirm({
                  title: t("modal.confirmDelete"),
                  content: t("modal.deleteContent"),
                  onOk() {
                    dispatch(
                      RequestAppAction.bulkDeleteAccounts({
                        data: { companyIds: selectedAccounts },
                        cbSuccess: () => {
                          const data: dataParams = {
                            page: 1,
                            limit: Limit,
                            search: encodeURIComponent(searchTerm),
                            showStripeInvalid: showStripeInvalid,
                            order: accountsOrder,
                            cbSuccess: () => {
                              setIsLoading(false);
                            },
                          };

                          if (!showActive) {
                            data["status"] = CompanyStatus.Active;
                          }

                          setShowActive(false);
                          setRowSelected(false);
                          dispatch(fetchCompaniesData(data));
                        },
                      })
                    );
                  },
                });
              }}
              title="Delete Selected"
              btnClass="outlined_btn"
            />
          )}
        </Space>
        <div
          style={{
            opacity: isLoading ? 0 : 1,
            width: "100%",
            height: "100%",
            display: "flex",
            overflowY: "auto",
          }}
        >
          <CustomTable
            columns={columns}
            data={tableData}
            setSelectedAccounts={setSelectedAccounts}
            setRowSelected={setRowSelected}
            loading={loading}
            showActive={showActive}
            showStripeInvalid={showStripeInvalid}
            accountsOrder={accountsOrder}
            onChangePage={(e: number) => {
              const data: dataParams = {
                page: e,
                limit: Limit,
                search: encodeURIComponent(searchTerm),
                order: accountsOrder,
                showStripeInvalid: showStripeInvalid,
              };

              if (!showActive) {
                data["status"] = CompanyStatus.Active;
              }

              dispatch(fetchCompaniesData(data));
            }}
            totalPages={companyData?.meta?.totalCount}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
