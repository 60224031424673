import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  planByIdState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  planData: null,
};

export const planByIdSlice = createSlice({
  name: "planById",
  initialState: INITIAL_STATE,
  reducers: {
    fetchPlanById: (state) => {
      state.planByIdState.isLoading = true;
    },
    fetchPlanByIdSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      planData: action.payload?.data,
    }),
    fetchPlanByIdFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    createPlan: (state) => {
      state.planByIdState.isLoading = true;
    },
    createPlanSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      planData: action.payload?.data,
    }),
    createPlanFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    updatePlanById: (state) => {
      state.planByIdState.isLoading = true;
    },
    updatePlanByIdSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      planData: action.payload?.data,
    }),
    updatePlanByIdFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    syncPlan: (state) => {
      state.planByIdState.isLoading = true;
    },
    syncPlanSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
      planData: action.payload?.data,
    }),
    syncPlanFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearPlanById: (state) => ({
      ...state,
      planByIdState: {
        ...state.planByIdState,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
      planData: null,
    }),
  },
});

export const {
  fetchPlanById,
  fetchPlanByIdFailure,
  fetchPlanByIdSuccess,
  createPlan,
  createPlanSuccess,
  createPlanFailure,
  updatePlanById,
  updatePlanByIdSuccess,
  updatePlanByIdFailure,
  syncPlan,
  syncPlanFailure,
  syncPlanSuccess,
  clearPlanById,
} = planByIdSlice.actions;
export const planByIdReducer = planByIdSlice.reducer;
