import { Breadcrumb } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./breadcrumbs.module.scss";

const AppBreadcrumbs = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  // const breadcrumbItems = pathSnippets.map((snippet, index) => {
  //   const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
  //   const breadcrumbText = snippet.replace(/-/g, " ");
  //   return (
  //     <Breadcrumb.Item key={url}>
  //       <Link to={url}>{breadcrumbText?.replace(/%20/g, " ")}</Link>
  //     </Breadcrumb.Item>
  //   );
  // });

  const breadcrumbItems = () => {
    const items = [];

    if (pathSnippets && pathSnippets.length > 0) {
      for (let i = 0; i < pathSnippets.length; i++) {
        const url = `/${pathSnippets.slice(0, i + 1).join("/")}`;
        const breadcrumbText = pathSnippets[i].replace(/-/g, " ");
        items.push({
          title: <a href={url} target="_blank">{breadcrumbText?.replace(/%20/g, " ")}</a>,
        });
      }
    }

    return items;
  };

  return (
    <Breadcrumb
      items={breadcrumbItems()}
      className={styles.breadcrumbs_wrapper}
    />
    // <Breadcrumb
    //   className={styles.breadcrumbs_wrapper}
    // >
    //   <Breadcrumb.Item></Breadcrumb.Item>
    //   {pathSnippets.length > 1 ? breadcrumbItems : <></>}
    // </Breadcrumb>
  );
};

export default AppBreadcrumbs;
