/* eslint-disable */
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button as Btn,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from "antd";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CompanyStatus,
  DateFormat,
  categoryOptions,
  labelStyle,
  provinces,
  states,
} from "src/constants/samples";
import Colors from "src/themes/colors";
import Button from "../button";
import Switch from "../switch";

import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { CAPOSTALCODEREGEX, Limit, USPOSTALCODEREGEX } from "src/constants/endpoints";
import { CreateCompany, UserDataType, Users } from "src/constants/types";
import { getHistoryData } from "src/store/selectors/features/history";
import { getRolesData } from "src/store/selectors/features/roles";
import { getUsersData, getUsersLoadingState } from "src/store/selectors/features/users";
import RequestAppAction from "src/store/slices/appActions";
import {
  deleteCompanyRecord,
  fetchCompaniesData,
} from "src/store/slices/features/company";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import { initiateUpdateCompany } from "src/store/slices/features/updateCompany";
import {
  clearUserData,
  fetchUsersData,
  initiateDeleteUser,
  initiateUpdateUser,
} from "src/store/slices/features/users";
import { capitalizeFirstLetter, countryCodeToCurrency, formatAddress, formatAddressToString } from "src/utils/helper-functions";
import UserTable from "../user-table";
import styles from "./userList.module.scss";
import { ROLES } from "src/constants/roles";
import { getPlanData } from "src/store/selectors/features/plans";
import { format } from "date-fns";
import { DefaultOptionType } from "antd/es/select";
import { useForm } from "antd/es/form/Form";
import { subscribeExistingAccount } from "src/store/slices/features/subscribeExistingAccount";

const { Title, Text } = Typography;

export const userColumns: ColumnsType<UserDataType> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: 100,
  },
  {
    title: "DIDs",
    dataIndex: "did",
    key: "did",
    fixed: "left",
  },
  {
    title: "Monthly Usage",
    dataIndex: "monthlyusage",
    key: "monthlyusage",
    fixed: "left",
  },
  {
    title: "Special Requests",
    dataIndex: "specialRequests",
    key: "specialRequests",
    fixed: "left",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const titleStyles = {
  borderBottom: `0.1rem solid ${Colors.Secondary}`,
  cursor: "pointer",
  color: Colors.Secondary,
};

const unSelectedTitleStyles = {
  borderBottom: `0.1rem solid transparent`,
  cursor: "pointer",
};

const { Option } = Select;

const UsersList = () => {
  const [expand, setExpand] = useState<number | null>(null);
  const [userExpand, setUserExpand] = useState<number | null>(null);
  const [companyExpand, setCompanyExpand] = useState<boolean>(false);
  const [stripeCustomerEdit, setStripeCustomerEdit] = useState<boolean>(false);
  const [stripeProductEdit, setStripeProductEdit] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<string>("users");
  const [isChecked, setIsChecked] = useState(false);
  const [currentHistories, setCurrentHistories] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(undefined);
  const [subscriptionPermission, setSubscriptionPermission] = useState<boolean>(false);
  const dispatch = useDispatch();
  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );
  const userData: any = useSelector(getUsersData);
  const usersLoadingState: any = useSelector(getUsersLoadingState);
  const [isLoading, setIsLoading] = useState(false);
  const roles: any = useSelector(getRolesData);
  const histories: any = useSelector(getHistoryData);
  const plans: any = useSelector(getPlanData);
  const navigate = useNavigate();
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const inputRef = useRef<any>(null);
  const [billed, setBilled] = useState(false);
  const [activePlans, setActivePlans] = useState(plans);
  const { t } = useTranslation();
  const [form] = useForm();
  const [isUnite, setIsUnite] = useState(false);

  const pathName: any = location.pathname;
  const tempId = pathName.match(/\d+$/);
  // eslint-disable-next-line
  const id = tempId[0];

  useEffect(() => {
    if (histories) {
      const tempData = histories.map((item: any) => {
        const newItem = { ...item };
        newItem.description = newItem.activity;
        delete newItem.activity;
        delete newItem.createdAt;
        delete newItem.updatedAt;
        return newItem;
      });

      setCurrentHistories(tempData);
    }
  }, [histories]);

  useEffect(() => {
    dispatch(RequestAppAction.fetchPlan({}));
  }, []);

  useEffect(() => {
    if (plans && plans.length > 0) {
      let filteredPlans = plans;

      if (userData?.billedExternally || isUnite) {
        filteredPlans = plans.filter((el: any) => {
          return (
            el.status === CompanyStatus.Active ||
            el.status === CompanyStatus.Pending
          );
        });
      } else {
        filteredPlans = plans.filter((el: any) => {
          return el.status === CompanyStatus.Active;
        });
      }

      setActivePlans(filteredPlans);
    }
  }, [plans]);

  useEffect(() => {
    setIsLoading(true);

    if (isChecked) {
      const data: { id: string; activeOnly?: boolean; search?: string } = {
        id: id,
      };

      if (searchVal) {
        data["search"] = searchVal;
      }

      dispatch(
        fetchUsersData({
          ...data,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    } else {
      dispatch(
        fetchUsersData({
          id: id,
          search: searchVal,
          activeOnly: true,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    }
  }, [isChecked]);

  useEffect(() => {
    dispatch(initiateFetchRoles());

    if (id) {
      dispatch(RequestAppAction.fetchHistory(id));
    }

    return () => {
      dispatch(clearUserData());
    };
  }, []);

  useEffect(() => {
    setSubscriptionPermission(
      ROLES.SUPERADMINFULL?.toLowerCase() === loggedIn?.role?.toLowerCase()
    )
  }, [loggedIn]);

  const isEdit = (isEdit: any, editType?: string) => {
    setUserExpand(editType === "userExpand" ? isEdit : null);
    setCompanyExpand(editType === "companyExpand" ? isEdit : false);
    setStripeCustomerEdit(editType === "stripeCustomerId" ? isEdit : false);
    setStripeProductEdit(editType === "stripeProductId" ? isEdit : false);
  };

  const handleSubscribe = () => {
    Modal.confirm({
      title: "Are you sure you want to create a new Stripe customer?",
      content: "The created customer will automatically be subscribed to the Free Plan. This action cannot be undone.",
      onOk() {
        subscribeAccount();
      },
    });
  };

  const subscribeAccount = () => {
    if (!userData?.id) return;

    const billingEmailAddress =
      userData.billingEmailAddress
      || userData.email
      || Array.isArray(userData.users) && userData.users.length > 0 ? userData.users[0].email : "";

    const payload: CreateCompany = {
      companyId: userData.id,
      name: userData.name,
      currency: userData.currency || "CAD",
      timeZone: userData.timeZone,
      status: userData.status,
      category: userData.category,
      notes: userData.notes,
      billedExternally: false,
      stripeProductId: -1,
      stripeSubscriptionId: "",
      stripeCustomerId: "new",
      subscriptionId: -1,
      billingEmailAddress: billingEmailAddress,
    };

    if (userData.country) {
      payload.country = userData.country;
    }
    if (userData.state) {
      payload.state = userData.state;
    }
    if (userData.city) {
      payload.city = userData.city;
    }
    if (userData.postalCode) {
      payload.postalCode = userData.postalCode;
    }

    dispatch(subscribeExistingAccount({
      data: payload,
      cbSuccess: () => {
        notification.success({ message: "Success" });
        handleFetchUsersData();
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
      },
    }));
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);

    const {
      postalCode,
      country,
      province,
      state,
      city
    } = values;

    const address = formatAddress(
      postalCode,
      country ?? selectedCountry,
      province,
      state,
      city,
    );

    const currency = address?.country
      ? countryCodeToCurrency[address.country]
      : userData?.currency
      ? userData.currency
      : "CAD";

    const updateComPayload = {
      timeZone: values.timezone,
      currency: currency,
      ...values,
      id: userData.id,
    };

    if (address.country && address.country !== "") {
      updateComPayload["country"] = address.country;
    }

    if (address.state && address.state !== "") {
      updateComPayload["state"] = address.state;
    }

    if (address.city && address.city !== "") {
      updateComPayload["city"] = address.city;
    }

    if (address.postalCode && address.postalCode !== "") {
      updateComPayload["postalCode"] = address.postalCode;
    }

    if (userData?.billedExternally === undefined || userData?.billedExternally === null) {
      updateComPayload["billedExternally"] = false;
    }

    if (values?.category === categoryOptions[1]) {
      delete updateComPayload["province"];
      delete updateComPayload["state"];
      delete updateComPayload["city"];
      delete updateComPayload["line1"];
      delete updateComPayload["line2"];
      delete updateComPayload["billingEmailAddress"];
    }

    Modal.confirm({
      title: "Are you sure you want to update?",
      content: "This action cannot be undone.",
      onOk() {
        dispatch(
          initiateUpdateCompany({
            data: updateComPayload,
            cbSuccess: () => {
              const data = {
                page: 1,
                limit: Limit,
              };
              dispatch(fetchCompaniesData(data));
              handleFetchUsersData();
            },
          })
        );
        isEdit(false);
      },
      onCancel() {
        setIsLoading(false);
      }
    });

    isEdit(false);
  };

  useEffect(() => {
    if (userData) {
      isEdit(false);
      setIsUnite(userData?.category === categoryOptions[1]);
    }
  }, [userData]);

  const validateUserName = (_: any, value: string, callback: any) => {
    const trimmedValue = value.trim();

    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regexEmail.test(trimmedValue)) {
      callback();
      return;
    }

    if (trimmedValue.length < 6 || trimmedValue.length > 20) {
      callback("Username should be between 6 to 20 characters");
      return;
    }

    // const regexUsername = /^[a-zA-Z0-9._]{6,20}$/;
    const regexUsername = /^[a-zA-Z0-9._]*$/;

    if (regexUsername.test(trimmedValue)) {
      callback();
    } else {
      callback("Username should only contain letters, numbers, periods, and underscores");
    }
  };

  const handleUpdateUser = (values: any, userId: number) => {
    let permissionNumber = values?.permissionLevel;

    setIsLoading(true);

    if (typeof permissionNumber === "string") {
      permissionNumber = roles[0]?.id;
    }

    const updateUserPayload: any = {
      id: userId,
      roleId: permissionNumber,
    };

    if (values?.username !== selectedUser?.username) {
      updateUserPayload["username"] = values?.username;
    }

    if (values?.contactName !== selectedUser?.name) {
      updateUserPayload["name"] = values?.contactName;
    }

    if (values?.mainEmail !== selectedUser?.email) {
      updateUserPayload["email"] = values?.mainEmail;
    }

    if (values?.additionalEmail !== selectedUser?.additionalEmail) {
      updateUserPayload["additionalEmail"] = values?.additionalEmail;
    }

    if (values?.status !== selectedUser?.status) {
      updateUserPayload["status"] = values?.status;
    }

    dispatch(initiateUpdateUser(updateUserPayload));

    setTimeout(() => {
      const myData: {
        id: string | null;
        activeOnly?: boolean;
        search?: string;
      } = {
        id: id,
      };

      if (!isChecked) {
        myData["activeOnly"] = true;
      }

      if (searchVal) {
        myData["search"] = searchVal;
      }

      dispatch(
        fetchUsersData({
          ...myData,
          cbSuccess: () => {
            setIsLoading(false);
          },
          cbFailure: () => {
            setIsLoading(false);
          },
        })
      );
    }, 1000);
  };

  const handleDeleteUser = (userId: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      content: "This action cannot be undone.",
      onOk() {
        dispatch(initiateDeleteUser(userId));
        handleFetchUsersData();
      },
      onCancel() {
        setIsLoading(false);
      }
    });
  };

  const navigateToNewUser = () => {
    return (
      <Link to={`/accounts/${userData?.id}/add-new-user`} state={userData}>
        <Button title="Add User" icon={<PlusCircleOutlined />} />
      </Link>
    );
  };

  const handleDeleteCompany = () => {
    Modal.confirm({
      title: t("modal.confirmDelete"),
      content: t("modal.deleteContent"),
      onOk() {
        dispatch(
          deleteCompanyRecord({
            id: userData?.id,
            cbSuccess: () => {
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            },
          })
        );
      },
    });
  };

  const handleSync = () => {
    Modal.confirm({
      title: "Are you sure you want to sync?",
      content: "This action cannot be undone.",
      onOk() {
        sync();
      },
    });
  };

  const sync = () => {
    dispatch(
      RequestAppAction.syncAccountStripe({
        id: userData?.id,
        cbSuccess: () => handleFetchUsersData(),
        cbFailure: () => handleFetchUsersData(),
      })
    );
  };

  const handleFetchUsersData = () => {
    const myData: {
      id: string | null;
      activeOnly?: boolean;
      search?: string;
    } = {
      id: id,
    };

    if (!isChecked) {
      myData["activeOnly"] = true;
    }

    if (searchVal) {
      myData["search"] = searchVal;
    }

    dispatch(
      fetchUsersData({
        ...myData,
        cbSuccess: () => {
          setIsLoading(false);
        },
        cbFailure: () => {
          setIsLoading(false);
        },
      })
    );
  }

  interface thisUser {
    roleId: number | string;
  }

  const rolesName = (thisUser: thisUser) => {
    const currentRole = roles?.filter(
      (role: any) => role?.id == thisUser?.roleId
    );

    if (currentRole) {
      return currentRole[0]?.id;
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    const getData = setTimeout(() => {
      if (!initialLoad) {
        setIsLoading(true);
      }

      const myData: {
        id: string | null;
        search: string;
        activeOnly?: boolean;
      } = {
        id: id,
        search: searchVal,
      };

      if (!isChecked) {
        myData["activeOnly"] = true;
      }

      dispatch(
        fetchUsersData({
          ...myData,
          cbSuccess: () => {
            setIsLoading(false);
            setInitialLoad(false);
          },
          cbFailure: () => {
            setIsLoading(false);
            setInitialLoad(false);
          },
        })
      );
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchVal]);

  const companyDetails = (name: string, detail: string, capitalize: any = "capitalize") => {
    return (
      <Space style={{ paddingRight: "2rem", paddingTop: "1rem" }}>
        <Col
          style={{
            maxWidth: "8rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Text strong style={{ color: "rgba(0,0,0,0.5)" }}>
              {name}
            </Text>
          </div>
          <Text
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textTransform: capitalize ?? "capitalize",
            }}
          >
            {detail || "-"}
          </Text>
        </Col>
      </Space>
    );
  };

  useEffect(() => {
    if (!userExpand) {
      setSelectedUser(null);
    }
  }, [userExpand]);

  useEffect(() => {
    setBilled(userData?.billedExternally === true);
  }, [companyExpand]);

  const onResetPassword = (username: string) => {
    if (!username) return;

    const param = { username: username };

    dispatch(
      RequestAppAction.postforgetPassword({
        data: param,
        cbSuccess: (res: { data: { message: string } }) => {
          notification.success({ message: res.data.message });
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones: allTimezones,
  });

  const timezoneList = () => {
    const list = [];

    for (let i = 0; i < timezoneOptions.length; i++) {
      list.push({
        key: `timezone-${i}`,
        label: timezoneOptions[i].label,
        value: timezoneOptions[i].value
      });
    }

    return list;
  };

  const planList = () => {
    const list = [];

    if (activePlans && activePlans.length > 0){
      for (let i = 0; i < activePlans.length; i++) {
        let activePlan: any = null;

        if (userData?.billedExternally) {
          activePlan = activePlans[i];
        } else {
          if (activePlans[i].stripeProductId) {
            activePlan = activePlans[i];
          }
        }

        if (activePlan?.id) {
          list.push({
            key: i,
            label: capitalizeFirstLetter(activePlan.stripeProductName || ""),
            value: activePlan.id
          });
        }
      }
    }

    return list;
  };

  const getOptionsList = (options: any) => {
    const list = [];

    if (options && Object.keys(options).length > 0) {
      for (const key in options) {
        list.push({
          key,
          label: options[key as keyof any], value: key
        });
      }
    }

    return list;
  };

  const timeZoneOptions: SelectProps['options'] = timezoneList();
  const planOptions: SelectProps['options'] = planList();
  const countryOptions: SelectProps['options'] = [
    { key: "CA", label: t("geoLocation.countries.CA"), value: "CA" },
    { key: "US",label: t("geoLocation.countries.US"), value: "US" },
  ];
  const provinceOptions: SelectProps['options'] = getOptionsList(provinces);
  const stateOptions: SelectProps['options'] = getOptionsList(states);

  const selectFilterOption = (input: string, option: DefaultOptionType | undefined) => {
    return (typeof option?.label === 'string'
      ? option.label.toLowerCase()
      : ''
    ).includes(input.toLowerCase());
  };

  const onChangeCountry = (value: string) => {
    setSelectedCountry(value);
    if (value === "CA") {
      form.resetFields(["state"]);
    } else if (value === "US") {
      form.resetFields(["province"]);
    }
  };

  const onChangeCategory = (value: any) => {
    setIsUnite(value === categoryOptions[1]);
  };

  const validatePostalCode = () => {
    const postalCode = form.getFieldValue("postalCode");
    const trimmedValue = postalCode ? postalCode.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (selectedCountry && selectedCountry === "CA" || !selectedCountry && userData?.country === "CA") {
      if (!CAPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.postalCodeInvalid")));
      }
    }

    if (selectedCountry && selectedCountry === "US" || !selectedCountry && userData?.country === "US") {
      if (!USPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.zipCodeInvalid")));
      }
    }

    return Promise.resolve();
  };

  const getPlanInitialValue = () => {
    if (userData?.stripeProductId && planOptions && planOptions.length > 0) {
      const findPlan = planOptions.filter((plan) => plan.value === userData.stripeProductId);
      if (findPlan && findPlan.length > 0) {
        return findPlan[0];
      }
    }
    return null;
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        width: "100%",
        // overflow: "hidden",
      }}
    >
      <div style={{ height: "100%", width: "100%" }}>
        <Spin
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          spinning={usersLoadingState || isLoading}
        >
          <Space
            direction="vertical"
            size={"large"}
            style={{ position: "relative" }}
            className={styles.user_list_wrapper_header}
          >
            <Space
              className={styles.user_list_header}
              // style={{ marginTop: !companyExpand ? "-0.8rem" : 0 }}
              size={"large"}
            >
              {companyExpand ? (
                <Form
                  form={form}
                  initialValues={{
                    ["name"]: userData?.name,
                    ["timezone"]: userData?.timeZone,
                    ["status"]: userData?.status,
                    ["category"]: userData?.category,
                    ["billingEmailAddress"]: userData?.billingEmailAddress,
                    ["notes"]: userData?.notes,
                    ["billedExternally"]: userData?.billedExternally,
                    ["country"]: userData?.country,
                    ["province"]: userData?.country === "CA" && userData?.state ? userData?.state : "",
                    ["state"]: userData?.country === "US" && userData?.state ? userData?.state : "",
                    ["city"]: userData?.city ? userData.city : "",
                    ["postalCode"]: userData?.postalCode ? userData.postalCode : "",
                  }}
                  layout={"vertical"}
                  onFinish={handleSubmit}
                >
                  <Row gutter={16}>
                    <Col>
                      <Form.Item
                        style={{ width: "18rem" }}
                        name="name"
                        label="company name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter company name",
                          },
                        ]}
                      >
                        <Input placeholder="Enter company name" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        name="timezone"
                        label="Timezone"
                        rules={[{ required: true, message: "Please select timezone" }]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Timezone"
                          options={timeZoneOptions}
                          filterOption={selectFilterOption}
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item
                        style={{ width: "18rem" }}
                        name="status"
                        label="status"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Status",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "18rem" }}
                          placeholder="Select Status"
                        >
                          {Object.values(CompanyStatus).map((timezone, index) => (
                            <Option key={index} value={timezone}>
                              {capitalizeFirstLetter(
                                timezone?.toString()?.toLocaleLowerCase()
                              )}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item
                        style={{ width: "18rem" }}
                        name="category"
                        label="category"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Category",
                          },
                        ]}
                      >
                        <Select placeholder="Select Category" onChange={onChangeCategory}>
                          {categoryOptions.map((category, index) => (
                            <Option key={index} value={category}>
                              {capitalizeFirstLetter(
                                category?.toString()?.toLocaleLowerCase()
                              )}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {!isUnite && (
                      <Col>
                        <Form.Item
                          style={{ width: "18rem" }}
                          name="billingEmailAddress"
                          label="billing email address"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Billing Email Address",
                            },
                          ]}
                        >
                          <Input placeholder="Billing email address" />
                        </Form.Item>
                      </Col>
                    )}

                    {!isUnite && (
                      <Col>
                        <Form.Item
                          style={{ width: "18rem" }}
                          name="country"
                          label="Country"
                          rules={[{ required: true, message: "Country is required" }]}
                        >
                          <Select
                            placeholder="Select Country"
                            options={countryOptions}
                            value={selectedCountry}
                            onChange={onChangeCountry}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {!isUnite && (
                      <Col>
                        {(selectedCountry && selectedCountry === "CA" || !selectedCountry && userData?.country === "CA") ? (
                          <Form.Item
                            style={{ width: "18rem" }}
                            name="province"
                            label="Province (Canada)"
                            rules={[
                              {
                                required: true,
                                message: "Province or Territory is required for Canada"
                              }
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Province or Territory"
                              options={provinceOptions}
                              filterOption={selectFilterOption}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            style={{ width: "18rem" }}
                            name="state"
                            label="State (US)"
                            rules={[
                              {
                                required: true,
                                message: "State is required for US"
                              }
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select State"
                              options={stateOptions}
                              filterOption={selectFilterOption}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    )}

                    {!isUnite && (
                      <Col>
                        <Form.Item
                          style={{ width: "18rem" }}
                          name="city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "City is required"
                            }
                          ]}
                        >
                          <Input type="text" placeholder="City" />
                        </Form.Item>
                      </Col>
                    )}

                    {!isUnite && (
                      <Col>
                        <Form.Item
                          style={{ width: "18rem" }}
                          name="postalCode"
                          label={(selectedCountry && selectedCountry === "CA" || !selectedCountry && userData?.country === "CA")
                            ? "Postal Code"
                            : "Zip Code"}
                          rules={[
                            {
                              required: true,
                              message: (selectedCountry && selectedCountry === "CA" || !selectedCountry && userData?.country === "CA")
                                ? "Postal code is required"
                                : "Zip code is required"
                            },
                            { validator: validatePostalCode },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder={(selectedCountry && selectedCountry === "CA" || !selectedCountry && userData?.country === "CA")
                              ? "Postal Code"
                              : "Zip Code"}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col>
                      <Form.Item
                        style={{ width: "18rem" }}
                        name="notes"
                        label="notes"
                      >
                        <Input placeholder="Notes" />
                      </Form.Item>
                    </Col>

                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      span={8}
                    >
                      <Form.Item
                        style={{
                          margin: 0,
                        }}
                        valuePropName="checked"
                        name="billedExternally"
                      >
                        <Checkbox
                          onChange={(e) => {
                            setBilled(e.target.checked);
                          }}
                          checked={billed}
                          disabled={true}
                        >
                          <Text>Billed externally</Text>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item style={{ marginTop: "0.5rem" }}>
                    <Space align="end" className={styles.form_button_wrapper}>
                      <Space size="middle" align="center">
                        <Button
                          title="Cancel"
                          btnClass="white_btn"
                          onBtnClick={() => setCompanyExpand(!companyExpand)}
                        ></Button>
                        <Button title="Save" buttonType="submit"></Button>
                      </Space>
                    </Space>
                  </Form.Item>
                </Form>
              ) : (
                <Row gutter={[10, 2]} style={{ marginBottom: "0.625rem" }}>
                  {companyDetails("ID", userData?.id)}

                  {companyDetails("Company Name", userData?.name)}

                  {companyDetails(
                    "Timezone",
                    userData?.timeZone ? (
                      parseTimezone(userData.timeZone)
                        ? parseTimezone(userData.timeZone).label
                        : userData.timeZone
                    ) : (
                      ""
                    )
                  )}

                  {companyDetails(
                    "Currency",
                    userData?.currency || "CAD",
                    "uppercase"
                  )}

                  {(!userData?.billedExternally && userData?.category !== categoryOptions[1]) && (
                    companyDetails(
                      "Billing Address",
                      formatAddressToString(userData?.postalCode, userData?.country, userData?.state)
                    )
                  )}

                  {(!userData?.billedExternally && userData?.category !== categoryOptions[1]) && (
                    companyDetails(
                      "Billing Email Address",
                      userData?.billingEmailAddress || "",
                      "none"
                    )
                  )}

                  {companyDetails(
                    "Status",
                    capitalizeFirstLetter(
                      userData?.status?.toString()?.toLocaleLowerCase()
                    )
                  )}

                  {companyDetails(
                    "Category",
                    capitalizeFirstLetter(
                      userData?.category?.toString()?.toLocaleLowerCase()
                    )
                  )}

                  <Space style={{ paddingRight: "2rem", paddingTop: "1rem" }}>
                    <Col
                      style={{
                        maxWidth: "8rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Text strong style={{ color: "rgba(0,0,0,0.5)" }}>
                          Note
                        </Text>
                      </div>
                      <Tooltip
                        style={{ borderRadius: 4 }}
                        title={userData?.notes}
                      >
                        <Text
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "default",
                          }}
                        >
                          {userData?.notes || "-"}
                        </Text>
                      </Tooltip>
                    </Col>
                  </Space>

                  {companyDetails(
                    "Current Month Usage",
                    `${userData?.usageCount || 0}/${userData?.usageLimit || 0}`
                  )}

                  {companyDetails(
                    "Plan",
                    `${userData?.stripeProducts?.stripeProductName ?? "-"}`
                  )}

                  {companyDetails(
                    "Billed Externally?",
                    userData?.billedExternally === true ? "Yes" : "No"
                  )}
                </Row>
              )}

              {!companyExpand ? (
                <Space
                  // style={{ marginTop: "1rem" }}
                  align="start"
                  size={12}
                  className={styles.user_list_header_actions}
                >
                  <Space
                    className={styles.search_div}
                    style={{
                      background: showSearch ? Colors.mediumGrey : Colors.White,
                      width: showSearch ? "15rem" : "auto",
                    }}
                  >
                    <Btn
                      shape="circle"
                      style={{
                        background: "transparent",
                        border: showSearch
                          ? "none"
                          : `0.1rem solid ${Colors.borderGray}`,
                      }}
                      icon={<SearchOutlined />}
                      onClick={() => setShowSearch(!showSearch)}
                    />
                    {showSearch && (
                      <div style={{ display: "flex", paddingRight: "0.5rem" }}>
                        <Input
                          ref={inputRef}
                          onChange={(e) => {
                            setSearchVal(e.target.value);
                          }}
                          placeholder="Search by name or email"
                          size={showSearch ? "middle" : "large"}
                          className={styles.search_input}
                        />
                        <CloseOutlined
                          onClick={() => {
                            setShowSearch(false);
                            setSearchVal("");
                          }}
                        />
                      </div>
                    )}
                  </Space>
                  <Button
                    shape="circle"
                    onBtnClick={() => isEdit(true, "companyExpand")}
                    title=""
                    icon={<EditOutlined />}
                  ></Button>
                  <Button
                    shape="circle"
                    title="title"
                    onBtnClick={() => {
                      handleDeleteCompany();
                      isEdit(false);
                    }}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Space>
              ) : (
                <></>
              )}
            </Space>
          </Space>
          <Space
            direction="vertical"
            size={"large"}
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              overflow: "hidden",
            }}
            className={styles.user_list_wrapper}
          >
            <Space
              direction="vertical"
              style={{
                paddingBottom: 0,
                marginTop: "-1rem",
                paddingRight: 20,
                display: "flex",
                height: "100%",
                width: "100%",
                position: "relative",
                overflow: "hidden",
              }}
              className={styles.user_list_content_headerings_wrapper}
            >
              <Space
                size={"large"}
                style={{ marginBottom: "-0.7rem" }}
                className={styles.user_list_content_headerings}
              >
                <Space style={{ margin: 0 }}>
                  <Title
                    level={5}
                    style={
                      selectedTab === "users"
                        ? titleStyles
                        : unSelectedTitleStyles
                    }
                    onClick={() => {
                      setSelectedTab("users");
                      isEdit(false);
                    }}
                  >
                    User & DIDs
                  </Title>
                  <Title
                    onClick={() => {
                      if (id) {
                        dispatch(RequestAppAction.fetchHistory(id));
                      }
                      setSelectedTab("activity");
                      isEdit(false);
                    }}
                    level={5}
                    style={
                      selectedTab === "activity"
                        ? titleStyles
                        : unSelectedTitleStyles
                    }
                  >
                    Activity History
                  </Title>
                  <Title
                    onClick={() => {
                      setSelectedTab("subscription");
                      isEdit(false);
                    }}
                    level={5}
                    style={
                      selectedTab === "subscription"
                        ? titleStyles
                        : unSelectedTitleStyles
                    }
                  >
                    Subscription
                  </Title>
                </Space>
                {selectedTab === "users" ? (
                  <Space
                    size="middle"
                    align="center"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <Switch
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e)}
                      label="Show Inactive"
                    />
                    {navigateToNewUser()}
                  </Space>
                ) : selectedTab === "subscription" && subscriptionPermission ? (
                  <Space
                    size="middle"
                    align="center"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <Button
                      title="Re-Sync Account Stripe Data"
                      icon={<ReloadOutlined />}
                      onBtnClick={handleSync}
                    />
                  </Space>
                ) : (
                  <></>
                )}
              </Space>
              <Divider style={{ margin: 0 }} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  // height: "60vh",
                  position: "relative",
                  // overflowY: "auto",
                  flexDirection: "column",
                }}
              >
                {selectedTab === "users" ? (
                  userData?.users?.length > 0 ? (
                    <>
                      {!companyExpand &&
                        userData?.users?.map((user: Users, index: number) => (
                          <div key={index} style={{ paddingBottom: "1rem" }}>
                            <Space
                              direction="vertical"
                              size={"large"}
                              style={{
                                borderRadius: "0.625rem",
                                width: "100%",
                                border: `0.06rem solid ${
                                  user.status === CompanyStatus.Active
                                    ? Colors.Green
                                    : Colors.LightGray
                                }`,
                              }}
                            >
                              <Space
                                align="center"
                                size={"large"}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  backgroundColor:
                                    user.status === CompanyStatus.Active
                                      ? Colors.LightGreen
                                      : user.status === CompanyStatus.Inactive
                                      ? Colors.LightGray
                                      : Colors.lightYellow,
                                  borderRadius: "0.625rem 0.625rem 0 0",
                                  padding: "0.4rem 0.825rem 0.4rem 0.825rem",
                                  width: "100%",
                                }}
                              >
                                <Space
                                  style={{ margin: 0, minWidth: "300px" }}
                                  size="middle"
                                  align="center"
                                >
                                  <Title level={5} style={{ margin: 0 }}>
                                    {`${index + 1}. ${
                                      user?.username || user?.email
                                    }`}
                                  </Title>
                                  {subscriptionPermission && user?.username && (
                                    <Button
                                      btnClass="white_btn"
                                      title="Reset Password"
                                      onBtnClick={() => onResetPassword(user.username)}
                                    />
                                  )}
                                </Space>
                                <Space
                                  style={{ margin: 0 }}
                                  size="middle"
                                  align="center"
                                >
                                  <Button
                                    shape="circle"
                                    title=""
                                    icon={<EditOutlined />}
                                    onBtnClick={() => (
                                      isEdit(user.id, "userExpand"),
                                      setSelectedUser(user)
                                    )}
                                  ></Button>
                                  <Button
                                    shape="circle"
                                    title=""
                                    icon={<DeleteOutlined />}
                                    onBtnClick={() => handleDeleteUser(user.id)}
                                  ></Button>
                                  <Button
                                    btnClass="white_btn"
                                    title="See More"
                                    icon={
                                      expand === index ? (
                                        <ArrowUpOutlined
                                          style={{ fontSize: 10 }}
                                        />
                                      ) : (
                                        <ArrowDownOutlined
                                          style={{ fontSize: 10 }}
                                        />
                                      )
                                    }
                                    onBtnClick={() => {
                                      isEdit(false);
                                      if (expand === index) setExpand(null);
                                      else setExpand(index);
                                    }}
                                  />
                                </Space>
                              </Space>

                              {userExpand === user.id ? (
                                <Space
                                  size={"large"}
                                  direction="vertical"
                                  className={styles.user_list_items_heading}
                                >
                                  <div className={styles.form_wrapper}>
                                    <Form
                                      layout={"vertical"}
                                      onFinish={(values) =>
                                        handleUpdateUser(values, user?.id)
                                      }
                                      initialValues={{
                                        ["username"]: user?.username,
                                        ["contactName"]: user?.name,
                                        ["permissionLevel"]: roles?.filter(
                                          (role: any) => role?.id === user?.roleId
                                        ).name,
                                        ["mainEmail"]: user?.email,
                                        ["additionalEmail"]:
                                          user?.additionalEmail,
                                      }}
                                    >
                                      <Row gutter={16}>
                                        <Col span={8}>
                                          <Form.Item
                                            name="username"
                                            label="Username"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Please enter username",
                                              },
                                              { validator: validateUserName },
                                            ]}
                                          >
                                            <Input placeholder="Enter Username" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item
                                            name="contactName"
                                            label="Contact Name"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter contact name",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Contact Name"
                                              defaultValue={user?.name}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                          <Form.Item
                                            name="permissionLevel"
                                            label="Permission Level"
                                            initialValue={rolesName(user)}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please select permission level",
                                              },
                                            ]}
                                          >
                                            <Select placeholder="Select Permission Level">
                                              {roles?.map((role: any) => (
                                                <Option
                                                  key={role?.id}
                                                  value={role?.id}
                                                >
                                                  {role?.name}
                                                </Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                          <Form.Item
                                            name="status"
                                            initialValue={user?.status}
                                            label="status"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Please Select Status",
                                              },
                                            ]}
                                          >
                                            <Select placeholder="Select Status">
                                              {Object.values(CompanyStatus).map(
                                                (timezone, ind) => (
                                                  <Option
                                                    key={ind}
                                                    value={timezone}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      timezone
                                                        ?.toString()
                                                        ?.toLocaleLowerCase()
                                                    )}
                                                  </Option>
                                                )
                                              )}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item
                                            name="mainEmail"
                                            label="Main Email"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter main email",
                                              },
                                              {
                                                type: "email",
                                                message:
                                                  "Please enter a valid email address",
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Enter Main Email" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item
                                            rules={[
                                              {
                                                type: "email",
                                                message:
                                                  "Please enter a valid email address",
                                              },
                                            ]}
                                            name="additionalEmail"
                                            label="Additional Email"
                                          >
                                            <Input placeholder="Enter Additional Email" />
                                          </Form.Item>
                                        </Col>
                                      </Row>

                                      <Form.Item>
                                        <Space
                                          align="end"
                                          className={styles.form_button_wrapper}
                                        >
                                          <Space size="middle" align="center">
                                            <Button
                                              onBtnClick={() => isEdit(false)}
                                              title="Cancel"
                                              btnClass="white_btn"
                                            ></Button>
                                            <Button
                                              title="Save"
                                              buttonType="submit"
                                            ></Button>
                                          </Space>
                                        </Space>
                                      </Form.Item>
                                    </Form>
                                  </div>
                                </Space>
                              ) : (
                                <Space
                                  size={"large"}
                                  direction="vertical"
                                  className={styles.user_list_items_heading}
                                >
                                  <Row
                                    gutter={[16, 0]}
                                    style={{ marginBottom: "0.625rem" }}
                                  >
                                    <Col span={3}>
                                      <Text className={styles.font_style}>
                                        User Name
                                      </Text>
                                    </Col>
                                    <Col span={4}>
                                      <Text className={styles.font_style}>
                                        Contact Name
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text className={styles.font_style}>
                                        Main Email
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text className={styles.font_style}>
                                        Additional Email
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text className={styles.font_style}>
                                        Permission Level
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text className={styles.font_style}>
                                        Status
                                      </Text>
                                    </Col>
                                    <Col span={5}>
                                      <Text className={styles.font_style}>
                                        Current Month Usage
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text style={{ textTransform: "none" }}>
                                        {user?.username}
                                      </Text>
                                    </Col>
                                    <Col span={4}>
                                      <Text style={{ textTransform: "none" }}>
                                        {user?.name}
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text style={{ textTransform: "none" }}>
                                        {" "}
                                        {user?.email}{" "}
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text style={{ textTransform: "none" }}>
                                        {user.additionalEmail}
                                      </Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text>{user?.roles?.name}</Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text>{user?.status}</Text>
                                    </Col>
                                    <Col span={5}>
                                      <Text>
                                        {user?.usageCount > 0 &&
                                        user?.usageLimit > 0
                                          ? `${user?.usageCount || 0}/${
                                              user?.usageLimit || 0
                                            } `
                                          : user.usageCount > 0
                                          ? user?.usageCount
                                          : 0}
                                      </Text>
                                    </Col>
                                  </Row>
                                  {expand === index ? (
                                    <UserTable
                                      isChecked={isChecked}
                                      columns={userColumns}
                                      userId={user.id}
                                      data={user.dids}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </Space>
                              )}
                            </Space>
                          </div>
                        ))}{" "}
                    </>
                  ) : (
                    <Space
                      size="middle"
                      align="center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div style={{ paddingTop: "2rem" }}>
                        <FileOutlined
                          style={{ fontSize: "3rem", color: Colors.LightGray }}
                        />
                      </div>
                      <div style={{ color: Colors.LightGray }}>
                        No Record found
                      </div>
                    </Space>
                  )
                ) : selectedTab === "activity" && !companyExpand ? (
                  <div style={{ marginBottom: "120px" }}>
                    <Space
                      direction="vertical"
                      size={"large"}
                      style={{
                        borderRadius: "0.625rem",
                        width: "100%",
                      }}
                    >
                      <Steps
                        progressDot
                        current={currentHistories.length}
                        responsive
                        direction="vertical"
                        items={currentHistories}
                      />
                    </Space>
                  </div>
                ) : selectedTab === "subscription" ? (
                  <Space
                    size={"large"}
                    direction="vertical"
                    style={{
                      justifyContent: "center",
                      borderRadius: "0.625rem",
                      width: "100%",
                    }}
                  >
                    {userData?.billedExternally !== true && userData?.hasInvalidStripeData ? (
                      <Space style={{ marginTop: "1rem" }}>
                        <ExclamationCircleOutlined style={{ color: "red" }} />
                        <Text style={{ color: "red", textTransform: "none" }}>
                          {userData.hasInvalidStripeData}
                          {" "}
                          Click the button to re-sync the data or change the Stripe Customer or Product ID.
                        </Text>
                      </Space>
                    ) : userData?.billedExternally === true ? (
                      <Space style={{ marginTop: "1rem" }}>
                        <ExclamationCircleOutlined style={{ color: "red" }} />
                        <Text style={{ color: "red", textTransform: "none" }}>
                          This account is billed externally, so no Stripe data is available. Subscribing plan will not be connected to the Stripe data.
                        </Text>
                      </Space>
                    ) : (
                      <></>
                    )}
                    {userData?.billedExternally === true ? (
                      <></>
                    ) : subscriptionPermission && stripeCustomerEdit ? (
                      <Form
                        onFinish={handleSubmit}
                        initialValues={{
                          ["stripeCustomerId"]: userData?.stripeCustomerId,
                        }}
                      >
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            <Text className={styles.font_style}>
                              Stripe Customer ID
                            </Text>
                          </Col>
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            <Form.Item
                              name="stripeCustomerId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Stripe Customer ID",
                                },
                              ]}
                              style={{ display: "flex", alignItems: "center", margin: 0 }}
                            >
                              <Input placeholder="Enter Stripe Customer ID" />
                            </Form.Item>
                          </Col>
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            <Button
                              title="Cancel"
                              btnClass="white_btn"
                              onBtnClick={() => isEdit(false)}
                            />
                            <Button
                              title="Save"
                              buttonType="submit"
                              disabled={!subscriptionPermission}
                            />
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      <Row
                        gutter={[16, 0]}
                        style={{ marginBottom: "0.625rem" }}
                      >
                        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                          <Text className={styles.font_style}>
                            Stripe Customer ID
                          </Text>
                        </Col>
                        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                          <Text style={{ textTransform: "none" }}>
                            {userData?.stripeCustomerId && (
                              <>
                                {userData.stripeCustomerId}
                                <br />
                                (
                                  {/* On test environment, put 'test/' in front of 'customers' */}
                                  <a
                                    href={`https://dashboard.stripe.com/customers/${userData.stripeCustomerId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Check on Stripe Dashboard
                                  </a>
                                )
                              </>
                            )}
                          </Text>
                        </Col>
                        {subscriptionPermission && (
                          <>
                            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                              <Button
                                title="Edit"
                                icon={<EditOutlined />}
                                onBtnClick={() => isEdit(true, "stripeCustomerId")}
                                disabled={!subscriptionPermission}
                              />
                              <Button
                                title="Create New"
                                icon={<PlusCircleOutlined />}
                                onBtnClick={() => {
                                  isEdit(true, "subscribeExistingAccount");
                                  handleSubscribe();
                                }}
                                disabled={!subscriptionPermission}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {subscriptionPermission && stripeProductEdit ? (
                      <Form
                        onFinish={handleSubmit}
                        initialValues={{
                          ["stripeProductId"]: getPlanInitialValue(),
                        }}
                      >
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            <Text className={styles.font_style}>
                              Subscribing Plan
                            </Text>
                          </Col>
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            {/* <Form.Item
                              name="stripeProductId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Stripe Product ID",
                                },
                              ]}
                              style={{ display: "flex", alignItems: "center", margin: 0 }}
                            >
                              <Input placeholder="Enter Stripe Product ID" />
                            </Form.Item> */}
                            <Form.Item
                              name="stripeProductId"
                              rules={[{ required: true, message: "Subscription plan is required" }]}
                            >
                              <Select
                                placeholder="Select a Plan"
                                options={planOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            <Button
                              title="Cancel"
                              btnClass="white_btn"
                              onBtnClick={() => isEdit(false)}
                            />
                            <Button
                              title="Save"
                              buttonType="submit"
                              disabled={!subscriptionPermission}
                            />
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      <Row
                        gutter={[16, 0]}
                        style={{ marginBottom: "0.625rem" }}
                      >
                        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                          <Text className={styles.font_style}>
                            Subscribing Plan
                          </Text>
                        </Col>
                        {/* <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                          <Text style={{ textTransform: "none" }}>
                            {userData?.stripeProductId}
                          </Text>
                        </Col> */}
                        <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                          <Text style={{ textTransform: "none" }}>
                            {userData?.stripeProducts?.stripeProductName
                              ? (
                                <Text style={{ textTransform: "none" }}>
                                  {userData.stripeProducts.stripeProductName}
                                </Text>
                              ) : userData?.stripeProducts
                              && userData.stripeProducts.length > 0
                              && userData.stripeProducts.map((product: any) => (
                                <Text style={{ textTransform: "none" }}>
                                  {product.stripeProductName}
                                </Text>
                              ))}
                          </Text>
                        </Col>
                        {subscriptionPermission && (
                          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                              style={{ borderRadius: 4 }}
                              title={
                                userData?.stripeSubscriptions?.cancelAt
                                ? "Cannot change the subscription that is scheduled to be cancelled."
                                : null
                              }
                            >
                              <Button
                                title="Edit"
                                icon={<EditOutlined />}
                                onBtnClick={() => isEdit(true, "stripeProductId")}
                                disabled={userData?.stripeSubscriptions?.cancelAt}
                              />
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    )}
                    {/* <Row
                      gutter={[16, 0]}
                      style={{ marginBottom: "0.625rem" }}
                    >
                      <Col span={8}>
                        <Text className={styles.font_style}>
                          Stripe Subscription ID
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Text style={{ textTransform: "none" }}>
                          {userData?.subscriptionId}
                        </Text>
                      </Col>
                    </Row> */}
                    {userData?.billedExternally === true ? (
                      <></>
                    ) : userData?.stripeSubscriptions && (
                      <>
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8}>
                            <Text className={styles.font_style}>
                              Subscription free-trial end date
                            </Text>
                          </Col>
                          <Col span={8}>
                            <Text style={{ textTransform: "none" }}>
                            <Text style={{ textTransform: "none" }}>
                                {userData?.stripeSubscriptions?.freeTrialEnd
                                  ? format(new Date(userData.stripeSubscriptions.freeTrialEnd), DateFormat)
                                  : "The account is not on a free trial"}
                              </Text>
                            </Text>
                          </Col>
                          {/* TODO: END FREE TIRAL RIGHT AWAY */}
                        </Row>
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8}>
                            <Text className={styles.font_style}>
                              Subscription Current Period Start Date
                            </Text>
                          </Col>
                          <Col span={8}>
                            <Text style={{ textTransform: "none" }}>
                              <Text style={{ textTransform: "none" }}>
                                {userData?.stripeSubscriptions?.currentPeriodStart
                                  ? format(new Date(userData.stripeSubscriptions.currentPeriodStart), DateFormat)
                                  : "-"}
                              </Text>
                            </Text>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8}>
                            <Text className={styles.font_style}>
                              Subscription Current Period End Date
                            </Text>
                          </Col>
                          <Col span={8}>
                            <Text style={{ textTransform: "none" }}>
                              <Text style={{ textTransform: "none" }}>
                                {userData?.stripeSubscriptions?.currentPeriodEnd
                                  ? format(new Date(userData.stripeSubscriptions.currentPeriodEnd), DateFormat)
                                  : "-"}
                              </Text>
                            </Text>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8}>
                            <Text className={styles.font_style}>
                              Subscription scheduled cancel date
                            </Text>
                          </Col>
                          <Col span={8}>
                            <Text style={{ textTransform: "none" }}>
                              <Text style={{ textTransform: "none" }}>
                                {userData.stripeSubscriptions.cancelAt ?? "-"}
                              </Text>
                            </Text>
                          </Col>
                        </Row>
                        <Row
                          gutter={[16, 0]}
                          style={{ marginBottom: "0.625rem" }}
                        >
                          <Col span={8}>
                            <Text className={styles.font_style}>
                              Subscription canceled date
                            </Text>
                          </Col>
                          <Col span={8}>
                            <Text style={{ textTransform: "none" }}>
                              <Text style={{ textTransform: "none" }}>
                                {userData.stripeSubscriptions.canceledAt ?? "-"}
                              </Text>
                            </Text>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Space>
                ) : (
                  <></>
                )}
              </div>
            </Space>
          </Space>
        </Spin>
      </div>
    </div>
  );
};

export default UsersList;
