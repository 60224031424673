import { Form, notification, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initiateFetchRoles } from "src/store/slices/features/roles";
import { CompanyStatus } from "src/constants/samples";
import RequestAppAction from "src/store/slices/appActions";
import Button from "../button";
import CreatePlanForm from "./create-plan-form";
import styles from "./createAccount.module.scss";

const CreatePlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStripePlan, setIsStripePlan] = useState<boolean>(true);

  useEffect(() => {
    dispatch(initiateFetchRoles());
  }, []);

  const onFinish = (values: any) => {
    const {
      status,
      name,
      tier,
      monthlyRate,
      messageRate,
      messageThreshold
    } = values;

    if (name && tier) {
      const payloadData: any = {
        status: status ?? CompanyStatus.Pending,
        stripeProductName: name,
        name: name,
        tier: tier,
        monthlyRate: monthlyRate ?? 0,
        messageRate: messageRate ?? 0,
        messageThreshold: messageThreshold ?? 0,
        isStripePlan: isStripePlan,
      };

      console.log(typeof monthlyRate);
      setIsLoading(true);

      dispatch(
        RequestAppAction.createPlan({
          data: payloadData,
          cbSuccess: (res: any) => {
            console.log(res);
            setIsLoading(false);
            notification.success({ message: "Plan created successfully" });
            navigate("/plans");
          },
          cbFailure: (error: any) => {
            notification.error({ message: error });
            setIsLoading(false);
            navigate("/plans");
          },
        })
      );
    }
  };

  return (
    <Space
      style={{
        height: "100%",
        display: "flex",
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
      className={styles.create_account_wrapper}
      direction="vertical"
      size={"large"}
    >
      <Form
        style={{
          height: "100%",
          display: "flex",
          width: "100%",
          position: "relative",
          flexDirection: "column",
        }}
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Space
          style={{
            display: "flex",
            padding: "1rem",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
            paddingBottom: "0.5rem",
          }}
        >
          <Button
            title="Save"
            buttonType="submit"
            disabled={isLoading}
          />
          <Button
            title="Cancel"
            onBtnClick={() => navigate(-1)}
            btnClass="outlined_btn"
          />
        </Space>
        <div
          style={{
            padding: "1rem",
            overflowY: "auto",
            display: "flex",
            height: window.innerHeight - 210,
            paddingBottom: "3rem",
            flexDirection: "column",
          }}
        >
          <CreatePlanForm
            isStripePlan={isStripePlan}
            setIsStripePlan={setIsStripePlan}
          />
        </div>
      </Form>
    </Space>
  );
};

export default CreatePlan;
