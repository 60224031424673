import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { CompanyStatus } from "src/constants/samples";
import RequestAppAction from "src/store/slices/appActions";
import { fetchUsersData } from "src/store/slices/features/users";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import Button from "../button";
const { Option } = Select;

interface UserTableProps {
  columns: any[];
  data: any[];
  setShowUsersList?: any;
  setShowCreateAccountForm?: any;
  setRowSelected?: any;
  userId: string | number;
  isChecked: boolean;
}

const UserTable: React.FC<UserTableProps> = ({
  columns,
  data,
  userId,
  isChecked,
}) => {
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [editedData, setEditedData] = useState<any>({});
  const [compareData, setCompareData] = useState<any>({});
  const [didsData, setDidsData] = useState<any>([]);
  const [addNew, setAddNew] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();

  useEffect(() => {
    setDidsData([...data, { fixed: "right", btn: "" }]);
  }, [data]);

  const handleEdit = (record: any) => {
    setEditingRow(record.id);
    setEditedData(record);
    form.setFieldsValue({
      did: record.did,
      status: record.status.toString().toLocaleLowerCase(),
      email: record.email,
    });
  };

  const handleDelete = (record: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this DID?",
      content: "This action cannot be undone.",
      onOk() {
        dispatch(
          RequestAppAction.deleteDid({
            userId: userId,
            id: record.id,
            cbSuccess: () => {
              const modifiedString = location.pathname.replace(
                "/accounts/",
                ""
              );

              const myData: { id: string | null; activeOnly?: boolean } = {
                id: modifiedString,
              };

              if (!isChecked) {
                myData["activeOnly"] = true;
              }

              dispatch(fetchUsersData(myData));
            },
          })
        );
      },
    });

    if (record.id === editingRow) {
      setEditingRow(null);
      form.resetFields();
    }
  };

  useEffect(() => {
    if (editingRow) {
      const tempData = {
        did: form.getFieldValue("did"),
        email: form.getFieldValue("email"),
        status: form.getFieldValue("status"),
      };

      setCompareData(tempData);
    }
  }, [editingRow]);

  const handleSaveClick = () => {
    form
      .validateFields()
      .then(() => {
        const updatedObj: { did?: string; email?: string; status: string } = {
          status: editedData.status.toString().toLocaleUpperCase(),
        };

        if (compareData?.did !== editedData?.did) {
          updatedObj["did"] = editedData?.did;
        }

        if (compareData?.email !== editedData?.email) {
          updatedObj["email"] = editedData?.email;
        }

        dispatch(
          RequestAppAction.updateDid({
            id: editingRow,
            data: updatedObj,
            cbSuccess: () => {
              const modifiedString = location.pathname.replace(
                "/accounts/",
                ""
              );

              const myData: { id: string | null; activeOnly?: boolean } = {
                id: modifiedString,
              };

              if (!isChecked) {
                myData["activeOnly"] = true;
              }

              dispatch(fetchUsersData(myData));
              setEditedData({});
              setCompareData({});
              setEditingRow(null);
              setAddNew(false);
            },
          })
        );
        setCompareData({});
        form.resetFields();
      })
      .catch(() => {});
  };

  const validateInput = (_: any, value: string, callback: any) => {
    const regexPattern = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

    if (regexPattern.test(value)) {
      callback();
    } else {
      callback("Did is not Valid");
    }
  };

  const handleInputChange = (e: any, fieldName: any) => {
    setEditedData({
      ...editedData,
      [fieldName]: e.target.value,
    });
  };

  const updatedColumns = columns.map((column: any) => {
    if (column.dataIndex === "id") {
      return {
        ...column,
        render: (_: string, record: any, index: number) => {
          return (
            <span>
              <div />
              {!("btn" in record) && index + 1}
            </span>
          );
        },
      };
    }

    if (column.dataIndex === "status") {
      return {
        ...column,
        render: (text: string, record: any) => {
          return editingRow === record.id ? (
            <Form.Item
              initialValue={
                record.status &&
                capitalizeFirstLetter(
                  record.status.toString().toLocaleLowerCase()
                )
              }
              style={{ margin: "1rem 0" }}
              name="status"
              rules={[{ required: true, message: "Company Status" }]}
            >
              <Select
                onChange={(val: any) => {
                  setEditedData({
                    ...editedData,
                    ["status"]:
                      val === "0"
                        ? CompanyStatus.Active
                        : val === "1"
                        ? CompanyStatus.Pending
                        : CompanyStatus.Inactive,
                  });
                }}
                placeholder="Active"
              >
                {Object.values(CompanyStatus).map((timezone, index) => (
                  <Option key={index}>
                    {capitalizeFirstLetter(
                      timezone?.toString().toLocaleLowerCase()
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <span>
              <div />
              {text &&
                capitalizeFirstLetter(text?.toString().toLocaleLowerCase())}
            </span>
          );
        },
      };
    }

    if (column.dataIndex === "did") {
      return {
        ...column,
        render: (text: string, record: any) =>
          record.id === editingRow ? (
            <span>
              <Form.Item
                initialValue={record.did}
                style={{ margin: "1rem 0" }}
                name="did"
                rules={[
                  { required: true, message: "did is Required" },
                  { validator: validateInput },
                ]}
              >
                <Input
                  placeholder="Please enter did"
                  onChange={(e) => handleInputChange(e, "did")}
                  value={text}
                />
              </Form.Item>
            </span>
          ) : (
            <span>{text}</span>
          ),
      };
    }

    if (column.dataIndex === "monthlyusage") {
      return {
        ...column,
        render: (_: string, record: any) => (
          <span>
            <div style={{ paddingLeft: "1rem" }}>{record?.usageCount}</div>
          </span>
        ),
      };
    }

    if (column.dataIndex === "email") {
      return {
        ...column,
        render: (text: string, record: any) =>
          record.id === editingRow ? (
            <span>
              <Form.Item
                initialValue={record.email}
                style={{ margin: "1rem 0" }}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter notification email"
                  onChange={(e) => handleInputChange(e, "email")}
                  value={text}
                />
              </Form.Item>
            </span>
          ) : (
            <span>{text || ""}</span>
          ),
      };
    }

    return column;
  });

  const handleAddNewDid = () => {
    form.resetFields();
    setEditedData({});
    setEditingRow(null);

    setTimeout(() => {
      const newObj = {
        companyId: didsData[0]?.companyId,
        createdAt: new Date(),
        did: "",
        email: "",
        id: "",
        status: "ACTIVE",
        tn: "",
        updatedAt: new Date(),
      };

      const tempDid = didsData.slice();
      tempDid.splice(tempDid.length - 1, 0, newObj);

      setAddNew(true);
      setDidsData(tempDid);
    }, 90);
  };

  useEffect(() => {
    if (addNew) {
      setEditingRow(didsData[didsData.length - 2].id);
      setEditedData(didsData[didsData.length - 2]);
    }
  }, [addNew]);

  const handleRemoveNew = () => {
    const tempDid = didsData.slice();
    tempDid.splice(tempDid.length - 2, 1);

    setDidsData(tempDid);
    setAddNew(false);
  };

  const handleSaveNewDid = () => {
    form
      .validateFields()
      .then(() => {
        const updatedObj = {
          did: editedData.did,
          email: editedData.email ? editedData.email : "",
          status: editedData.status,
        };

        dispatch(
          RequestAppAction.addDid({
            id: userId,
            data: updatedObj,
            cbSuccess: () => {
              const modifiedString = location.pathname.replace(
                "/accounts/",
                ""
              );

              const myData: { id: string | null; activeOnly?: boolean } = {
                id: modifiedString,
              };

              if (!isChecked) {
                myData["activeOnly"] = true;
              }

              dispatch(fetchUsersData(myData));
              setEditedData({});
              setEditingRow(null);
              setAddNew(false);
            },
          })
        );
      })
      .catch(() => {});
  };

  return (
    <Form style={{ marginTop: "-1.2rem" }} form={form}>
      <Table
        size="small"
        pagination={false}
        columns={[
          ...updatedColumns,
          {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 160,
            render: (_, record) =>
              editingRow === record.id ? (
                <Space size="middle" align="center">
                  <Button
                    title="Save"
                    onBtnClick={() =>
                      addNew ? handleSaveNewDid() : handleSaveClick()
                    }
                    btnClass="white_btn"
                  ></Button>
                  <Button
                    shape="circle"
                    title=""
                    icon={<DeleteOutlined />}
                    onBtnClick={() =>
                      !addNew ? handleDelete(record) : handleRemoveNew()
                    }
                  ></Button>
                  {record.id === editingRow && (
                    <Button
                      shape="circle"
                      title=""
                      icon={<CloseOutlined />}
                      onBtnClick={() => (
                        setEditedData({}),
                        setEditingRow(null),
                        addNew && handleRemoveNew()
                      )}
                    ></Button>
                  )}
                </Space>
              ) : !("btn" in record) ? (
                <Space size="middle" align="center">
                  <Button
                    disabled={addNew}
                    shape="circle"
                    title=""
                    icon={<EditOutlined />}
                    onBtnClick={() => handleEdit(record)}
                  ></Button>
                  <Button
                    disabled={addNew}
                    shape="circle"
                    title=""
                    icon={<DeleteOutlined />}
                    onBtnClick={() => handleDelete(record)}
                  ></Button>
                </Space>
              ) : (
                <Button
                  disabled={addNew || (editingRow ? true : false)}
                  title="Add New DID"
                  onBtnClick={() => {
                    handleAddNewDid();
                  }}
                />
              ),
          },
        ]}
        dataSource={didsData}
        rowKey={(user) => user.id}
        onRow={() => {
          return {
            onClick: () => {},
          };
        }}
      />
    </Form>
  );
};

export default UserTable;
