export enum RequestTypes {
  LOGIN_REQUEST = "login/fetchLoginData",
  UPDATE_PASSWORD_REQUEST = "password/putPassword",
  FETCH_USER_REQUEST = "userSettings/fetchUserSettings",
  UPDATE_USER_REQUEST = "userSettings/putUserSettings",
  UPDATE_USER_DETAIL_REQUEST = "userDetails/UpdateUserDetails",
  FETCH_USER_DETAIL_REQUEST = "userDetails/fetchUserDetails",
  FETCH_ROLES = "roles/initiateFetchRoles",
  FETCH_COMPANIES_DATA = "company/fetchCompaniesData",
  DELETE_COMPANY_RECORD = "company/deleteCompanyRecord",
  UPDATE_COMPANY = "updateCompany/initiateUpdateCompany",
  CREATE_COMPANY = "createCompany/initiateCreateCompany",
  DELETE_USER = "users/initiateDeleteUser",
  UPDATE_USER = "users/initiateUpdateUser",
  FETCH_USERS = "users/fetchUsersData",
  DELETE_DID_REQUEST = "did/deleteDid",
  UPDATE_DID_REQUEST = "did/updateDid",
  ADD_DID_REQUEST = "did/addNewDId",
  REPORTS_REQUEST = "report/fetchReports",
  REPORTS_BY_ID = "reportById/fetchReportsById",
  SIGN_OUT_REQUEST = "signout/postSignout",
  HISTORY_REQUEST = "history/fetchHistory",
  ADD_USER_REQUEST = "newUser/addNewUser",
  POST_FORGET_PASSWORD = "login/postForgetPassword",
  POST_VERIFY_TOKEN = "verifyToken/fetchVerifyToken",
  POST_RESET_PASSWORD = "resetPassword/fetchResetPassword",
  BULK_DELETE_ACCOUNTS = "company/fetchBulkDeleteAccounts",
  AVAILABILITY_USERNAME_REQUEST = "availability/fetchAvailablityUsername",
  AVAILABILITY_DID_REQUEST = "availability/fetchAvailablityDid",
  GET_PLAN_REQUEST = "plan/fetchPlans",
  PULL_STRIPE_PRODUCTS_REQUEST = "plan/pullStripeProducts",
  CREATE_PLAN_REQUEST = "planById/createPlan",
  FETCH_PLAN_BY_ID_REQUEST = "planById/fetchPlanById",
  UPDATE_PLAN_BY_ID_REQUEST = "planById/updatePlanById",
  SYNC_PLAN_REQUEST = "planById/syncPlan",
  SYNC_ACCOUNT_STRIPE_REQUEST = "userDetails/syncAccountStripe",
  SUBSCRIBE_EXISTING_ACCOUNT_REQUEST = "subscribeExistingAccount/subscribeExistingAccount",
  HANDLE_EXISTING_ACCOUNT_STRIPE_REQUEST = "company/handleExistingAccountStripe",
}
