import { Modal, Spin } from "antd";
import { PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/button";
import PlansTable from "src/components/plans-table";
import { Limit } from "src/constants/endpoints";
import { getSearchTerm } from "src/store/selectors/features/app";
import { getPlanData, getPlanLoadingState } from "src/store/selectors/features/plans";
import RequestAppAction from "src/store/slices/appActions";
import { setSearchTerm } from "src/store/slices/features/app";
import { ROLES } from "src/constants/roles";

const plansColumns: any = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: 100,
    sorter: (a: any, b: any) => a.id - b.id,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Tier",
    dataIndex: "tier",
    key: "tier",
    fixed: "left",
    width: 100,
    sorter: (a: any, b: any) => {
      if (!a?.tier && !b?.tier) return -1;
      if (!a?.tier) return 1;
      if (!b?.tier) return -1;
      return a.tier < b.tier ? -1 : a.tier > b.tier ? 1 : 0;
    },
    sortDirections: ["ascend", "descend", "ascend"],
  },
  // {
  //   title: "Stripe Product Id",
  //   dataIndex: "stripeProductId",
  //   key: "stripeProductId",
  //   fixed: "left",
  // },
  {
    title: "Plan Name (DB)",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    sorter: (a: any, b: any) => {
      if (!a?.name && !b?.name) return 1;
      if (!a?.name) return -1;
      if (!b?.name) return 1;
      return a.name < b.name ? 1 : a.name > b.name ? -1 : 0;
    },
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Plan Name (Stripe)",
    dataIndex: "stripeProductName",
    key: "stripeProductName",
    fixed: "left",
    sorter: (a: any, b: any) => {
      if (!a?.stripeProductName && !b?.stripeProductName) return 1;
      if (!a?.stripeProductName) return -1;
      if (!b?.stripeProductName) return 1;
      return a.stripeProductName < b.stripeProductName
        ? 1
        : a.stripeProductName > b.stripeProductName
        ? -1
        : 0;
    },
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Monthly Rate",
    dataIndex: "monthlyRate",
    key: "monthlyRate",
    fixed: "left",
  },
  // {
  //   title: "Monthly Rate Price Id",
  //   dataIndex: "monthlyRatePriceId",
  //   key: "monthlyRatePriceId",
  // },
  {
    title: "Message Rate",
    dataIndex: "messageRate",
    key: "messageRate",
  },
  // {
  //   title: "Message Rate Price Id",
  //   dataIndex: "messageRatePriceId",
  //   key: "messageRatePriceId",
  // },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const data: any[] = [];

for (let i = 0; i < 600; i++) {
  data.push({
    id: i + 1,
    didstatus: i % 3 !== 0 ? "Active" : "Inactive",
    did: Math.round(Math.random() * 1000000),
    username: `user_name${i}`,
    companyname: `Organic${i}`,
    outbound: `${Math.round(Math.random() * 100)}/2500`,
    inbound: `${Math.round(Math.random() * 100)}/2500`,
  });
}

export const Plans: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [myRange, setMyRange] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [planData, setPlanData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPlanByIdState: any = useSelector(getPlanData);
  const isPlansLoading = useSelector(getPlanLoadingState);
  const searchTerm = useSelector(getSearchTerm);

  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  useEffect(() => {
    if (getPlanByIdState) {
      setPlanData(getPlanByIdState);
    }
  }, [getPlanByIdState]);

  useEffect(() => {
    dispatch(
      RequestAppAction.fetchPlan({
        cbSuccess: (res: any) => {
          console.log(res);
        },
      })
    );
  }, []);

  function buildApiUrl({
    page,
    search,
    startDate,
    endDate,
  }: any) {
    const limit = Limit;

    const queryParams = new URLSearchParams();

    page && queryParams.append("page", page);
    startDate && queryParams.append("startDate", startDate);
    endDate && queryParams.append("endDate", endDate);
    search && queryParams.append("search", search);
    queryParams.append("limit", limit);

    return `?${queryParams.toString()}`;
  }

  useEffect(() => {
    const query = {
      page: currentPage,
      search: "",
    };

    dispatch(
      RequestAppAction.fetchPlan({
        data: buildApiUrl({ ...query }),
        cbSuccess: () => {
          setIsLoading(false);
        },
      })
    );

    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  const changePage = (page: any) => {
    const query = {
      page: page,
      search: searchTerm,
      startDate: myRange.length > 1 ? myRange[0] : null,
      endDate: myRange.length > 1 ? myRange[1] : null,
    };

    dispatch(
      RequestAppAction.fetchPlan({
        data: buildApiUrl(query),
        cbSuccess: () => {
          setIsLoading(false);
        },
      })
    );

    setCurrentPage(page);
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      const query = {
        page: currentPage,
        search: searchTerm,
        startDate: myRange.length > 1 ? myRange[0] : null,
        endDate: myRange.length > 1 ? myRange[1] : null,
      };

      dispatch(
        RequestAppAction.fetchPlan({
          data: buildApiUrl(query),
          cbSuccess: () => {
            setIsLoading(false);
          },
        })
      );
    }, 800);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (myRange.length > 1) {
      if (myRange[0] === "" && myRange[1] === "") {
        setMyRange([]);
        setCurrentPage(1);

        const query = {
          page: 1,
          search: searchTerm,
        };

        dispatch(
          RequestAppAction.fetchPlan({
            data: buildApiUrl(query),
            cbSuccess: () => {
              setIsLoading(false);
            },
          })
        );
      }
    }
  }, [myRange]);

  const handleSync = () => {
    Modal.confirm({
      title: "Are you sure you want to pull Stripe Products?",
      content: "This action cannot be undone.",
      onOk() {
        dispatch(
          RequestAppAction.pullStripeProducts({
            cbSuccess: () => {
              dispatch(
                RequestAppAction.fetchPlan({
                  cbSuccess: () => {
                    setIsLoading(false);
                  },
                })
              );
            },
          })
        );
      },
    });
  };

  return (
    <div style={{ padding: "1rem 3rem", height: "100%", position: "relative" }}>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Button
          title="Create New Plan"
          icon={<PlusCircleOutlined />}
          onBtnClick={() => navigate("/plans/create-new-plan")}
        />
        <Button
          title="Pull All Stripe Products"
          icon={<ReloadOutlined />}
          onBtnClick={handleSync}
          disabled={ROLES.SUPERADMINFULL?.toLowerCase() !== loggedIn?.role?.toLowerCase()}
        />
      </div>
      <Spin
        style={{
          height: "100%",
          width: "100%",
          background: "transparent",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isLoading || isPlansLoading ? 1 : 0,
        }}
        spinning={isLoading || isPlansLoading}
      >
        <div
          style={{
            marginTop: "1rem",
            opacity: isLoading ? 0 : 1,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              overflowY: "auto",
              flex: "1 1 auto",
            }}
          >
            <PlansTable
              spin={isLoading}
              columns={plansColumns}
              data={planData ?? []}
              onChangePage={(page: any) => {
                changePage(page);
              }}
              totalPages={getPlanByIdState?.meta?.totalCount}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};
