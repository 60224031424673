import { createSelector } from "reselect";

/**
 *
 * @param state
 */

const planSelector = (state: TReduxState) => state.features.planById;

export const getBaseUrl = createSelector(
  planSelector,
  (app) => app.baseUrl
);

export const getPlanByIdData = createSelector(
  planSelector,
  // (app) => app.apiStatus.data
  (app) => app.planData
);

export const getPlanByIdState = createSelector(
  planSelector,
  (app) => app.planByIdState
);

export const getPlanByIdLoadingState = createSelector(
  getPlanByIdState,
  (plan) => plan.isLoading
);
