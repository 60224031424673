import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { RequestTypes } from "../types";
import {
  subscribeExistingAccountFailure,
  subscribeExistingAccountSuccess
} from "../slices/features/subscribeExistingAccount";

const appservice = new AppService();

function* subscribeExistingAccount(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.subscribeExistingAccount,
      baseUrl,
      data
    );

    yield put(subscribeExistingAccountSuccess({ ...response }));

    let subscribeData = {};

    if (response.data) {
      subscribeData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(subscribeData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(subscribeExistingAccountFailure({ statusCode, statusText }));
  }
}

export function* watchSubscribeExistingAccount() {
  yield takeLatest(
    RequestTypes.SUBSCRIBE_EXISTING_ACCOUNT_REQUEST,
    subscribeExistingAccount
  );
}
